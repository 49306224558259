import { Modal } from 'antd';
import classNames from 'classnames';
import './modal.scss';

interface IPropsModal {
  className?: string;
  handleOk?: () => void;
  title?: string | React.ReactNode;
  open?: boolean;
  onCancel?: () => void;
  okText?: string;
  children?: React.ReactNode;
  width?: number | string;
}

const ModalCommon = (props: IPropsModal) => {
  return (
    <Modal
      centered
      className={classNames('custom-modal', props.className, {
        hideFooter: !props?.handleOk,
      })}
      title={props.title}
      open={props.open}
      onOk={props.handleOk}
      onCancel={props.onCancel}
      cancelText='キャンセル'
      okText={props.okText}
      closeIcon={<></>}
      width={props.width}
    >
      {props.children}
    </Modal>
  );
};
export default ModalCommon;
