import { useLazyQuery } from '@apollo/client';
import { useRequest } from 'ahooks';
import { Select } from 'antd';
import React, { useState } from 'react';
import EmptySelect from 'src/components/EmptySelect';
import { FILTER_SCHOOL_YEARS } from 'src/graphql/School.graphql';

const FormItemMultipleSchoolYear = ({
  onChange,
  form,
  schoolId,
  require,
  disabled,
  schoolFacultyId,
  resetFields,
}: any) => {
  const [getSchoolYear, { data, loading }] = useLazyQuery(FILTER_SCHOOL_YEARS);
  const [listOptions, setListOptions] = useState([]);

  useRequest(
    async () => {
      if (!schoolId) return [];

      if (require && !schoolId) return [];

      return getSchoolYear({
        variables: {
          input: schoolFacultyId
            ? {
                schoolIds: schoolId?.length ? schoolId : [schoolId],
                schoolFacultyIds: schoolFacultyId,
              }
            : {
                schoolIds: schoolId?.length ? schoolId : [schoolId],
              },
        },
        onCompleted: (data) => {
          const listYear = data?.filterSchoolYears
            .reduce((result: any[], item: any) => {
              if (result.indexOf(+item.year) === -1) result.push(+item.year);
              return result;
            }, [])
            .sort((a: any, b: any) => b - a);

          const newData =
            listYear.map((it: any) => {
              return {
                label: it,
                value: it,
              };
            }) ?? [];
          setListOptions(newData);
        },
      });
    },
    {
      debounceWait: 300,

      refreshDeps: [schoolId, schoolFacultyId],
    },
  );

  const onChangeVal = (val: any) => {
    const newVal = data?.filterSchoolYears
      ?.filter((it: any) => {
        if (val === +it.year) return it;
      })
      .map((item: any) => item.id);
    onChange(newVal);

    if (resetFields) form.resetFields(resetFields);
  };

  return (
    <Select
      loading={loading}
      disabled={disabled}
      onChange={onChangeVal}
      placeholder='実施年度'
      notFoundContent={<EmptySelect />}
      options={listOptions}
    />
  );
};

export default FormItemMultipleSchoolYear;
