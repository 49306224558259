import { ROUTE_PATH } from 'src/routes/route.constant';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const ACCESS_ROLE_KEY = 'role';
export const REF_TOKEN = 'refreshToken';
export const INFO_USER = 'info_user';

export const listPath = [
  [
    '/',
    ROUTE_PATH.QUESTION_MANAGEMENT,
    ROUTE_PATH.IMAGE_MANAGEMENT,
    ROUTE_PATH.MOCK_TEST_CREATION,
    ROUTE_PATH.QUIZ_MANAGEMENT,
    ROUTE_PATH.TEST_LIST,
    ROUTE_PATH.QUESTION_MANAGEMENT_DETAIL,
  ],
  [ROUTE_PATH.QUIZ_DELIVERY, ROUTE_PATH.NATIONAL_TRIAL_EXAMINATION],
  [ROUTE_PATH.SCHOOL_SUMAMARY, ROUTE_PATH.STUDENT_SUMAMARY, ROUTE_PATH.MINI_TEST_ANALYSIS],
  [
    ROUTE_PATH.OVERALL_RESULTS,
    ROUTE_PATH.STATISTIC_BY_SCHOOL,
    ROUTE_PATH.RATE_OF_CORRECT_ANSWER,
    ROUTE_PATH.RANK_STATISTIC,
    ROUTE_PATH.PERSONAL_RESULT,
  ],
];

export const firstTitle = ['テスト管理', ' 配信管理', ' 学習分析', ' 全国模試集計'];

export const PageSizeOptions = [
  {
    value: 10,
    label: '10/ページ',
  },
  {
    value: 20,
    label: '20/ページ',
  },
  {
    value: 30,
    label: '30/ページ',
  },
  {
    value: 40,
    label: '40/ページ',
  },
  {
    value: 50,
    label: '50/ページ',
  },
];
