/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { atom, useAtom } from 'jotai';

export type TSubjectCode = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J';
export type PointAverageSubject =
  | 'PSA'
  | 'PSB'
  | 'PSC'
  | 'PSD'
  | 'PSE'
  | 'PSF'
  | 'PSG'
  | 'PSH'
  | 'PSI'
  | 'PSJ';

type Data = {
  identityNumber: string;
  nationalRank: number;
  point: number;
  subjects: {
    code: TSubjectCode;
    point: number;
    totalPoint: number;
  }[];
};

type NationalAggregation = {
  data?: [];
  pointAverage: number;
  rateAverageExactly: number;
  A: number;
  B: number;
  C: number;
  D: number;
  E: number;
  F: number;
  G: number;
  H: number;
  I: number;
  J: number;
  averagePointSubject: {
    A: number;
    B: number;
    C: number;
    D: number;
    E: number;
    F: number;
    G: number;
    H: number;
    I: number;
    J: number;
  };
};

const national = atom<NationalAggregation>({
  data: [],
  pointAverage: 0,
  rateAverageExactly: 0,
  A: 0,
  B: 0,
  C: 0,
  D: 0,
  E: 0,
  F: 0,
  G: 0,
  H: 0,
  I: 0,
  J: 0,
  averagePointSubject: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
  },
});

export const useNational = () => {
  const [v] = useAtom(national);

  return v;
};

export const useNationalAggregation = () => {
  const [v, setValue] = useAtom(national);
};
