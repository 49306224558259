import { gql } from '@apollo/client';

export const GET_SUBJECTS = gql`
  query GET_SUBJECTS {
    subjects {
      id
      name
      code
    }
  }
`;

export const GET_SUBJECT = gql`
  query GET_SUBJECTS($subjectId: String!) {
    subjects(where: { id: { equals: $subjectId } }) {
      id
      name
      code
    }
  }
`;

export const GET_SECTIONS = gql`
  query GET_SECTIONS {
    sections {
      id
      name
    }
  }
`;

export const GET_CHAPTERS_BY_SUBJECT = gql`
  query GetChapterBySubjectId($subjectId: String!) {
    getChapterBySubjectId(subjectId: $subjectId) {
      # category {
      #   id
      #   name
      # }
      id
      name
    }
  }
`;

export const GET_CATEGORIES_BY_CHAPTER = gql`
  query GetChapterBySubjectId($chapterId: String!) {
    getCategoriesByChapterId(chapterId: $chapterId) {
      id
      name
    }
  }
`;

export const GET_LIST_QUESTIONS = gql`
  query AdminGetQuestions(
    $where: QuestionWhereInput
    $skip: Int
    $take: Int
    $orderBy: [QuestionOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    adminGetQuestions(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      id
      accountId
      category {
        id
        name
      }
      chapter {
        id
        name
      }
      createdAt
      identityNumber
      level
      no
      public
      school {
        name
      }
      account {
        fullName
        role
        school {
          name
          id
        }
      }
      school {
        code
      }
      status
      subject {
        id
        name
      }
      subjectId
      title
    }
    totalQuestion(where: $where)
  }
`;

export const GET_RANDOM_LIST_QUESTIONS = gql`
  query AdminRandomGetQuestions(
    $where: QuestionWhereInput
    $skip: Int
    $take: Int
    $orderBy: [QuestionOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    getRandomQuestions(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      id
      accountId
      category {
        id
        name
      }
      chapter {
        id
        name
      }
      createdAt
      identityNumber
      level
      no
      public
      school {
        name
      }
      account {
        fullName
        role
      }
      school {
        code
      }
      status
      subject {
        id
        name
      }
      subjectId
      title
    }
    totalQuestion(where: $where)
  }
`;

export const SEARCH_MINI_TEST = gql`
  query GetQuestionById($input: SeachMiniTestInput!) {
    adminSearchMiniTest(input: $input) {
      formTestQuestion {
        id
        formTestId
        questionId
        question {
          id
          accountId
          category {
            id
            name
          }
          chapter {
            id
            name
          }
          createdAt
          identityNumber
          level
          no
          public
          school {
            name
          }
          account {
            fullName
            role
          }
          school {
            code
          }
          status
          subject {
            id
            name
          }
          subjectId
          title
        }
      }
      id
      name
      poem
      school {
        abbreviations
        code
        createdAt
        deletedAt
        id
        name
        updatedAt
      }
      schoolId
      section {
        id
        name
      }
      sectionId
      semester
      status
      updatedAt
    }
  }
`;

export const GET_DETAIL_QUESTION = gql`
  query GetQuestionById($where: QuestionWhereUniqueInput!) {
    getQuestionById(where: $where) {
      id
      level
      identityNumber
      title
      file
      description
      subjectId
      chapterId
      categoryId
      public
      accountId
      isInUsedByTest
      schoolId
      answer {
        id
        isCorrect
        text
        explainText
        explainImageOrVideo
      }
      account {
        role
        id
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation AdminCreateQuestion($data: CreateQuestionInput!) {
    adminCreateQuestion(data: $data)
  }
`;

export const UPDATE_STATUS_QUESTION = gql`
  mutation AdminChangeStatusQuestion($where: QuestionWhereUniqueInput!) {
    adminChangeStatusQuestion(where: $where)
  }
`;

export const DELETE_QUESTION = gql`
  mutation AdminDeleteQuestion($where: QuestionWhereUniqueInput!) {
    adminDeleteQuestion(where: $where)
  }
`;

export const UPDATE_QUESTION = gql`
  mutation AdminUpdateQuestion($data: UpdateQuestionInput!, $where: QuestionWhereUniqueInput!) {
    adminUpdateQuestion(data: $data, where: $where)
  }
`;
