export const ROUTE_PATH = {
  QUESTION_MANAGEMENT: '/question-management',
  TEST_LIST: '/test-list',
  QUESTION_MANAGEMENT_DETAIL: '/question-management/:id',
  MOCK_TEST_CREATION: '/mock-test-creation/:id',
  QUIZ_MANAGEMENT: '/quiz-management/:id',
  IMAGE_MANAGEMENT: '/image-management',
  QUIZ_CREATION: '/quiz-creation',
  NATIONWIDE: '/nationwide-trial-delivery',
  QUIZ_DELIVERY: '/quiz-delivery',
  USER_MANAGEMENT: '/user-management',
  ADMIN_MANAGEMENT: '/admin-management',
  SCHOOL_MANAGEMENT: '/school-management',
  SCHOOL_SUMAMARY: '/school-summary',
  STUDENT_SUMAMARY: '/student-summary',
  // STUDENT_SUMMARY_DETAIL: '/student-summary/:id',
  LOGIN: '/login',
  QUESTION_CATEGORY: '/question-category',
  MINI_TEST_ANALYSIS: '/mini-test-analysis',
  MINI_TEST_ANALYSIS_DETAIL: '/mini-test-analysis/:id',
  NATIONAL_TRIAL_EXAMINATION: '/national-trial-examination',
  OVERALL_RESULTS: '/overall-results',
  STATISTIC_BY_SCHOOL: '/statistic-by-school',
  RATE_OF_CORRECT_ANSWER: '/rate-of-correct-answer',
  RANK_STATISTIC: '/rank-statistic',
  CHANGE_PASSWORD: '/change-password',
  UPDATE_EXAM: '/update-exam',
  PERSONAL_RESULT: '/personal-result',
};
