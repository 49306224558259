/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Form, Select, Table } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import LocaleTable from 'src/components/LocaleTable';
import { PopupNoti } from 'src/components/PopupNoti';
import { IconGraph } from 'src/components/icons/IconGraph';
import { Aggregate_All, Aggregate_All_National, Get_Years } from 'src/graphql/Aggregate.graphql';
import { GET_SUBJECTS } from 'src/graphql/Question.graphql';
import { formatPercent } from 'src/lib/utils';
import { listSchoolHardCode } from 'src/utils';
import * as XLSX from 'xlsx';
import ModalOverallResults from './components/ModalOverallResults';
import styles from './index.module.scss';

const OverallResults = () => {
  const [form] = Form.useForm();

  const [visibleModal, setVisibleModal] = useState({
    isOpen: false,
    record: {},
  });
  const { data: subjectsData } = useQuery(GET_SUBJECTS);

  const [getAggregateAll, { data: listAggregateAll, loading }] = useLazyQuery(Aggregate_All, {
    fetchPolicy: 'no-cache',
  });
  const [getAggregateAllNational, { data: listAggregateAllNational, loading: loadingNational }] =
    useLazyQuery(Aggregate_All_National, {
      fetchPolicy: 'no-cache',
      onError(error: any) {
        PopupNoti.error(
          `${
            error?.message === 'NOT_FINISH_ALL_SCHOOL_YET'
              ? '集計が行われていますので、少々お待ちください。'
              : error?.message
          }`,
        );
      },
    });

  const { data: listYears } = useQuery(Get_Years);

  const sortMarkets = (array: any[], sortArray: any[]) => {
    return [...array].sort(
      (a, b) => sortArray.indexOf(a.abbreviations) - sortArray.indexOf(b.abbreviations),
    );
  };

  const dataFormat = useMemo(() => {
    const newList = listAggregateAll?.aggregateAll.map((item: any) => {
      return {
        ...item,
        schools: sortMarkets(item.schools, listSchoolHardCode),
      };
    });
    return newList;
  }, [listAggregateAll]);

  const subjectColumns = subjectsData?.subjects?.map((it: any) => {
    return {
      title: it.name,
      align: 'center',
      width: 80,
      render: (_: any, record: any) => {
        const subject = record?.subjects?.find((v: any) => {
          return v.code === it.code;
        });

        if (subject?.maxPoint === 0) return <p>0%</p>;

        return <p>{formatPercent((subject?.point / subject?.maxPoint) * 100)}%</p>;
      },
    };
  });

  const getColumns = (testTimeLabel?: any): any[] => {
    return [
      {
        title: !!testTimeLabel ? `${testTimeLabel}回目` : `回目`,
        dataIndex: 'abbreviations',
        align: 'center',
        width: 70,
        fixed: 'left',
      },
      {
        title: '全体結果',
        fixed: 'left',
        dataIndex: 'schools',
        key: 'schools',
        children: [
          {
            title: '受験者',
            dataIndex: 'totalStudent',
            key: 'totalStudent',
            width: 60,
            fixed: 'left',
            align: 'center',
          },
          {
            title: '合格者',
            dataIndex: 'totalPass',
            width: 60,
            key: 'totalPass',
            fixed: 'left',
            align: 'center',
          },
          {
            title: '合格率',
            dataIndex: 'passRatio',
            key: 'passRatio',
            width: 60,
            fixed: 'left',
            align: 'center',
            render: (v: any) => {
              return <p>{formatPercent(v * 100)}%</p>;
            },
          },
          {
            title: '平均点',
            dataIndex: 'pointAverage',
            width: 60,
            key: 'pointAverage',
            fixed: 'left',
            align: 'center',
            render: (v: any) => {
              return <p>{formatPercent(v)}</p>;
            },
          },
        ],
      },
      {
        title: '教科別(正答率)',
        dataIndex: 'subjects',
        key: 'subjects',
        children: subjectColumns ? subjectColumns : [],
      },
      {
        title: '昨年 合格率',
        dataIndex: 'passRatioPreviousYear',
        width: 60,
        fixed: 'right',
        align: 'center',
        render: (v: any) => {
          return <p>{formatPercent(v) === 0 ? '' : formatPercent(v * 100) + '%'}</p>;
        },
      },
      {
        title: '合格率 昨対',
        width: 60,
        fixed: 'right',
        align: 'center',
        render: (_: any, record: any) => {
          return (
            <div>
              {record.passRatioPreviousYear === 0
                ? ''
                : +((record.passRatio - record.passRatioPreviousYear) * 100).toFixed(2) + '%'}
            </div>
          );
        },
      },
      {
        key: 'operation',
        fixed: 'right',
        align: 'center',
        width: 60,
        render: (record: any) => (
          <Button
            className='border-none'
            onClick={() => {
              setVisibleModal({
                isOpen: true,
                record: {
                  ...record,
                  testTime: testTimeLabel,
                },
              });
            }}
          >
            <IconGraph />
          </Button>
        ),
      },
    ];
  };

  const onFilter = (changedValues: any) => {
    getAggregateAll({
      variables: {
        input: {
          year: dayjs(changedValues?.year, 'YYYY').add(7, 'day').toISOString(),
        },
      },
    });
    getAggregateAllNational({
      variables: {
        input: {
          year: dayjs(changedValues?.year, 'YYYY').add(7, 'day').toISOString(),
        },
      },
    });
  };

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const refillArr = (arr: any) => {
      const newAr = arr.map((item: number, idx: number) => {
        if (idx < 3 || idx === 4) {
          return item;
        } else return `${+(item * 100).toFixed(2)}%`;
      });
      return newAr;
    };
    dataFormat?.map((_: any, idx: number) => {
      const tbl = document.getElementById(`tableItem-${idx + 1}`);
      const ws = XLSX.utils.table_to_sheet(tbl);
      const dataWithoutEmptyRows = XLSX.utils
        .sheet_to_json(ws, { header: 1 })
        .filter((row: any) => row.join('').trim() !== '')
        .map((arr: any, idx) => {
          if (arr.indexOf('グラフ') !== -1) arr.splice(arr.indexOf('グラフ'), 1);

          if (idx > 1) {
            return [...refillArr(arr)];
          } else return [...arr];
        });
      const wsFinal = XLSX.utils.aoa_to_sheet(dataWithoutEmptyRows);
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 0, c: 5 }, e: { r: 0, c: 14 } },
        { s: { r: 0, c: 15 }, e: { r: 1, c: 15 } },
        { s: { r: 0, c: 16 }, e: { r: 1, c: 16 } },
      ];
      wsFinal['!merges'] = merge;
      XLSX.utils.book_append_sheet(wb, wsFinal, `${idx + 1}回目`);
    });

    XLSX.writeFile(wb, 'Overall-Results.xlsx');
  };

  return (
    <div className={styles.overallResults}>
      <Form
        form={form}
        onValuesChange={onFilter}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Form.Item name='year'>
          <Select
            placeholder='実施年度'
            style={{ width: '150px' }}
            options={listYears?.getYears?.map((it: any) => {
              return {
                label: it?.year,
                value: it?.year,
              };
            })}
          />
        </Form.Item>
        <Button
          className={classNames(styles.btn, styles.btnPrint)}
          onClick={handleExportExcel}
          disabled={isEmpty(listAggregateAll?.aggregateAll)}
        >
          <img src={process.env.PUBLIC_URL + '/images/do-excel.svg'} alt='' width={26} />
          ダウンロード
        </Button>
      </Form>

      {dataFormat?.map((it: any, idx: any) => {
        return (
          <div key={it.testTime} className={styles.wrapTable}>
            <Table
              id={`tableItem-${idx + 1}`}
              columns={getColumns(it.testTime)}
              dataSource={it?.schools}
              scroll={{ x: 1880, y: 550 }}
              pagination={false}
              locale={{
                emptyText: <LocaleTable />,
              }}
              rowKey={'abbreviations'}
              className='table-custom'
              summary={(pageData) => {
                const nationalData = listAggregateAllNational?.aggregateAllNational.find(
                  (item: any) => item.testTime === it.testTime,
                ).dataTotal;
                return (
                  !!pageData.length && (
                    <Table.Summary fixed='top'>
                      <Table.Summary.Row>
                        <Table.Summary.Cell className='font-bold' align='center' index={0}>
                          全体
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={1}>
                          <p>{nationalData?.totalStudent || 0}</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={2}>
                          <p
                          // className='text-red-700 bg-red-100 p-2'
                          >
                            {nationalData?.totalPass || 0}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={3}>
                          <p>{formatPercent(nationalData?.passRatio * 100)}%</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={4}>
                          <p>{formatPercent(nationalData?.point)}</p>
                        </Table.Summary.Cell>
                        {nationalData?.subjects?.map((it: any, idx: number) => {
                          return (
                            <Table.Summary.Cell
                              className='font-bold'
                              align='center'
                              index={idx + 5}
                              key={idx + 5}
                            >
                              <p>{`${formatPercent((it?.point / it?.maxPoint) * 100)}%`}</p>
                            </Table.Summary.Cell>
                          );
                        })}

                        <Table.Summary.Cell className='font-bold' align='center' index={15}>
                          {nationalData?.passRatioPreviousYear === 0
                            ? ''
                            : +(nationalData?.passRatioPreviousYear * 100).toFixed(2) + '%'}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={16}>
                          {nationalData?.passRatioPreviousYear === 0
                            ? ''
                            : +(
                                (nationalData?.passRatio - nationalData?.passRatioPreviousYear) *
                                100
                              ).toFixed(2) + '%'}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={17}>
                          グラフ
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )
                );
              }}
            />
          </div>
        );
      })}

      {!listAggregateAll?.aggregateAll?.length && (
        <Table
          id={`tableAll`}
          columns={getColumns()}
          dataSource={[]}
          scroll={{ x: 1880, y: 550 }}
          pagination={false}
          className='table-custom'
          loading={loading}
          locale={{
            emptyText: <LocaleTable />,
          }}
        />
      )}
      {!!visibleModal.isOpen && (
        <ModalOverallResults
          visible={visibleModal}
          setVisible={setVisibleModal}
          listAggregateAllNational={listAggregateAllNational?.aggregateAllNational}
        />
      )}
    </div>
  );
};
export default OverallResults;
