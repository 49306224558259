import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import test_ctrl from 'src/assets/images/sideBarImg/test_ctrl.png';
import test_ctrl_active from 'src/assets/images/sideBarImg/test_ctrl_active.png';
import Group from 'src/assets/images/sideBarImg/Group.png';
import Group_active from 'src/assets/images/sideBarImg/Group_active.png';
import icon_study from 'src/assets/images/sideBarImg/icon_study.png';
import icon_study_active from 'src/assets/images/sideBarImg/icon_study_active.png';
import lock from 'src/assets/images/sideBarImg/lock.png';
import lock_active from 'src/assets/images/sideBarImg/lock_active.png';
import category from 'src/assets/images/sideBarImg/category.png';
import category_active from 'src/assets/images/sideBarImg/category_active.png';
import person from 'src/assets/images/sideBarImg/person.png';
import person_active from 'src/assets/images/sideBarImg/person_active.png';
import school from 'src/assets/images/sideBarImg/school.png';
import school_active from 'src/assets/images/sideBarImg/school_active.png';

import { Link, useLocation } from 'react-router-dom';
import { SidebarWrapper } from './styled';
import { ROUTE_PATH } from 'src/routes/route.constant';
import { usePermission } from 'src/hook/usePermission';
type MenuItem = Required<MenuProps>['items'][number];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8', 'sub9'];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const { isTeacher, isSchoolManager, isAdminApp, isFormTeacher } = usePermission();

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem => {
    if ((isTeacher || isFormTeacher) && (key === 'sub7' || key === 'sub6' || key === '5'))
      return null;

    if (isSchoolManager && (key === 'sub6' || key === '5')) return null;

    if (!isAdminApp && key === 'sub8') return null;

    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  };

  const items: MenuItem[] = [
    // テスト管理
    getItem(
      <Link
        to='#'
        className={
          [
            '/question-management',
            '/image-management',
            '/mock-test-creation/new',
            '/quiz-management/new',
            '/test-list',
            '/question-management/new',
            '/',
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        テスト管理
      </Link>,
      'sub1',
      <img
        src={
          [
            '/question-management',
            '/image-management',
            '/mock-test-creation/new',
            '/quiz-management/new',
            '/test-list',
            '/question-management/new',
            '/',
          ].indexOf(location.pathname) !== -1
            ? test_ctrl_active
            : test_ctrl
        }
        alt=''
        className='icon-width'
      />,
      [
        getItem(
          <Link
            to='/question-management'
            className={
              location.pathname === '/question-management' ||
              location.pathname === '/' ||
              location.pathname === '/question-management/new'
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            問題登録
          </Link>,
          '1',
        ),
        getItem(
          <Link
            to='/image-management'
            className={
              location.pathname === '/image-management' ? 'active-link margin-link' : 'margin-link'
            }
          >
            画像登録（一括登録用）
          </Link>,
          '2',
        ),
        getItem(
          <Link
            to='/quiz-management/new'
            className={
              location.pathname.includes('/quiz-management')
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            小テスト作成
          </Link>,
          '3',
        ),
        getItem(
          <Link
            to='/test-list'
            className={
              location.pathname.includes('/test-list') ? 'active-link margin-link' : 'margin-link'
            }
          >
            テスト一覧
          </Link>,
          '4',
        ),
        getItem(
          <Link
            to='/mock-test-creation/new'
            className={
              location.pathname.includes('/mock-test-creation')
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            全国模試作成
          </Link>,
          '5',
        ),
      ],
    ),

    // 配信管理
    getItem(
      <Link
        to='#'
        className={
          ['/quiz-delivery', '/national-trial-examination'].indexOf(location.pathname) !== -1
            ? 'active-link'
            : 'inactive-link'
        }
      >
        配信管理
      </Link>,
      'sub2',
      <img
        src={
          ['/quiz-delivery', '/national-trial-examination'].indexOf(location.pathname) !== -1
            ? test_ctrl_active
            : test_ctrl
        }
        alt=''
        className='icon-width'
      />,
      [
        getItem(
          <Link
            to='/quiz-delivery'
            className={
              location.pathname === '/quiz-delivery' ? 'active-link margin-link' : 'margin-link'
            }
          >
            小テスト配信
          </Link>,
          '7',
        ),
        getItem(
          <Link
            to='/national-trial-examination'
            className={
              location.pathname === '/national-trial-examination'
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            全国模試配信
          </Link>,
          '8',
        ),
      ],
    ),

    // 学習分析
    getItem(
      <Link
        to='#'
        className={
          [
            ROUTE_PATH.SCHOOL_SUMAMARY,
            ROUTE_PATH.STUDENT_SUMAMARY,
            ROUTE_PATH.MINI_TEST_ANALYSIS,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : ''
        }
      >
        学習分析
      </Link>,
      'sub3',
      <img
        src={
          [
            ROUTE_PATH.SCHOOL_SUMAMARY,
            ROUTE_PATH.STUDENT_SUMAMARY,
            ROUTE_PATH.MINI_TEST_ANALYSIS,
          ].indexOf(location.pathname) !== -1
            ? icon_study_active
            : icon_study
        }
        alt=''
        className='icon-width'
      />,
      [
        getItem(
          <Link
            to={ROUTE_PATH.SCHOOL_SUMAMARY}
            className={
              location.pathname === ROUTE_PATH.SCHOOL_SUMAMARY
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            学校別利用状況
          </Link>,
          '121',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.STUDENT_SUMAMARY}
            className={
              location.pathname === ROUTE_PATH.STUDENT_SUMAMARY
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            個人別利用状況
          </Link>,
          '233',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.MINI_TEST_ANALYSIS}
            className={
              location.pathname === ROUTE_PATH.MINI_TEST_ANALYSIS
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            小テスト分析
          </Link>,
          '234',
        ),
      ],
    ),

    // 全国模試集計
    getItem(
      <Link
        to='#'
        className={
          [
            ROUTE_PATH.OVERALL_RESULTS,
            ROUTE_PATH.STATISTIC_BY_SCHOOL,
            ROUTE_PATH.RATE_OF_CORRECT_ANSWER,
            ROUTE_PATH.RANK_STATISTIC,
            ROUTE_PATH.PERSONAL_RESULT,
          ].indexOf(location.pathname) !== -1
            ? 'active-link'
            : ''
        }
      >
        全国模試集計
      </Link>,
      'sub4',
      <img
        src={
          [
            ROUTE_PATH.OVERALL_RESULTS,
            ROUTE_PATH.STATISTIC_BY_SCHOOL,
            ROUTE_PATH.RATE_OF_CORRECT_ANSWER,
            ROUTE_PATH.RANK_STATISTIC,
            ROUTE_PATH.PERSONAL_RESULT,
          ].indexOf(location.pathname) !== -1
            ? Group_active
            : Group
        }
        alt=''
        className='icon-width'
      />,
      [
        getItem(
          <Link
            to={ROUTE_PATH.OVERALL_RESULTS}
            className={
              location.pathname === ROUTE_PATH.OVERALL_RESULTS
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            全体結果
          </Link>,
          '41',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.STATISTIC_BY_SCHOOL}
            className={
              location.pathname === ROUTE_PATH.STATISTIC_BY_SCHOOL
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            学校別集計
          </Link>,
          '42',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.RATE_OF_CORRECT_ANSWER}
            className={
              location.pathname === ROUTE_PATH.RATE_OF_CORRECT_ANSWER
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            問題別正答率
          </Link>,
          '43',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.RANK_STATISTIC}
            className={
              location.pathname === ROUTE_PATH.RANK_STATISTIC
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            順位集計
          </Link>,
          '44',
        ),
        getItem(
          <Link
            to={ROUTE_PATH.PERSONAL_RESULT}
            className={
              location.pathname === ROUTE_PATH.PERSONAL_RESULT
                ? 'active-link margin-link'
                : 'margin-link'
            }
          >
            個人結果
          </Link>,
          '45',
        ),
      ],
    ),

    // ユーザー一覧
    getItem(
      <Link
        to='/user-management'
        className={location.pathname === '/user-management' ? 'active-link' : ''}
      >
        ユーザー一覧
      </Link>,
      'sub5',
      <img
        src={location.pathname === '/user-management' ? person_active : person}
        alt=''
        className='icon-width'
      />,
    ),

    // 管理者登録
    getItem(
      <Link
        to='/admin-management'
        className={location.pathname === '/admin-management' ? 'active-link' : ''}
      >
        管理者登録
      </Link>,
      'sub6',
      <img
        src={location.pathname === '/admin-management' ? person_active : person}
        alt=''
        className='icon-width'
      />,
    ),

    // 学校マスタ管理
    getItem(
      <Link
        to='/school-management'
        className={location.pathname === '/school-management' ? 'active-link' : ''}
      >
        学校マスタ管理
      </Link>,
      'sub7',
      <img
        src={location.pathname === '/school-management' ? school_active : school}
        alt=''
        className='icon-width'
      />,
    ),

    // 問題カテゴリ登録
    getItem(
      <Link
        to='/question-category'
        className={location.pathname === '/question-category' ? 'active-link' : ''}
      >
        問題カテゴリ登録
      </Link>,
      'sub8',
      <img
        src={location.pathname === '/question-category' ? category_active : category}
        alt=''
        className='icon-width'
      />,
    ),

    // パスワード変更
    getItem(
      <Link
        to='/change-password'
        className={location.pathname === '/change-password' ? 'active-link' : ''}
      >
        パスワード変更
      </Link>,
      'sub9',
      <img
        src={location.pathname === '/change-password' ? lock_active : lock}
        alt=''
        className='icon-width'
      />,
    ),
  ];

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <SidebarWrapper>
      <Menu
        mode='inline'
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        // style={{ width: 256 }}
        items={items}
      />
    </SidebarWrapper>
  );
};

export default Sidebar;
