import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE_PATH } from 'src/routes/route.constant';
import { getAccessToken } from 'src/store/auth/useAuthStore';

const AuthLayout = () => {
  const isLogin = getAccessToken();

  if (isLogin) return <Navigate to={ROUTE_PATH.QUESTION_MANAGEMENT} replace />;

  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
};

export default AuthLayout;
