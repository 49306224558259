import beauty_logo from 'src/assets/images/beauty_logo.png';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGOUT } from 'src/graphql/Auth.graphql';
import { Col, Row } from 'antd';
import { useRequest } from 'ahooks';
import { useProfileStore } from 'src/store/profile/useProfileStore';
import { useAuthStore } from 'src/store/auth/useAuthStore';

function Navbar() {
  const [handleLogout] = useMutation(LOGOUT);
  const { profile } = useProfileStore();
  const { onLogout } = useAuthStore();

  const requestLogout = useRequest(
    async (accountId) => {
      return handleLogout({
        variables: {
          accountId,
        },
      });
    },
    {
      manual: true,
      onSuccess: (res: any) => {
        if (res.data.logout.loggedOut) {
          onLogout();
          window.location.href = '/login';
        }
      },
      onError: (e) => {},
    },
  );

  const onLogoutActions = () => {
    requestLogout.run(profile.id);
  };

  return (
    <Row align={'middle'} justify='space-between' className='navbar header_nav' wrap={false}>
      <Col flex='300px' className='navbar-left'>
        <Link to='/question-management'>
          <img src={beauty_logo} alt='' />
        </Link>
      </Col>
      <Col flex='auto' className='navbar-right'>
        <button className='btn-logout' onClick={onLogoutActions} disabled={requestLogout.loading}>
          ログアウト
        </button>
      </Col>
    </Row>
  );
}

export default Navbar;
