/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumb, Layout, Row, Spin, theme } from 'antd';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { firstTitle, listPath } from 'src/constants/init.constants';
import { usePermission } from 'src/hook/usePermission';
import { ROUTE_PATH } from 'src/routes/route.constant';
import { getAccessToken } from 'src/store/auth/useAuthStore';
import { useProfileStore } from 'src/store/profile/useProfileStore';
import './layout.scss';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
const { Content, Sider } = Layout;

const IGNORE_ROLE_TEACHER = [
  '/school-management',
  '/admin-management',
  '/mock-test-creation',
  '/question-category',
];

const IGNORE_ROLE_SCHOOL_MANAGER = [
  '/admin-management',
  '/mock-test-creation',
  '/question-category',
];
const IGNORE_ROLE_ADMIN_NATIONAL = ['/question-category'];

const MainLayout = () => {
  const isLogin = getAccessToken();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const { profile } = useProfileStore();
  const { isTeacher, isFormTeacher, isAdminApp, isSchoolManager, isAdminNational } =
    usePermission();

  const titlePage = (isTitle?: boolean) => {
    const conditionDynamic = location.pathname.includes('/question-management/');
    const conditionQuizManagement = location.pathname.includes('/quiz-management/');
    const miniTestAnalysis = location.pathname.includes('/mini-test-analysis/');
    const nationalExam = location.pathname.includes('/mock-test-creation/');
    // const studentStatistic = location.pathname.includes('/student-summary/');

    if (conditionDynamic) {
      return '個別問題登録';
    }
    if (conditionQuizManagement) {
      return '小テスト作成';
    }
    if (nationalExam) {
      return '全国模試作成';
    }
    // if (studentStatistic) {
    //   return '学生統計';
    // }

    if (miniTestAnalysis) {
      return isTitle ? '小テスト結果' : '小テスト分析（クラス別)';
    }

    switch (location.pathname) {
      case '/':
      case '/question-management':
        return '問題登録';
      case '/image-management':
        return '画像登録';
      case '/mock-test-creation':
        return '全国模試作成';
      case '/quiz-creation':
        return '小テスト作成';
      case '/test-list':
        return 'テスト一覧';
      case '/nationwide-trial-delivery':
        return '全国統一模試配信';
      case '/quiz-delivery':
        return '小テスト配信';
      case '/national-trial-examination':
        return '全国統一模試配信';
      case '/learning-analysis':
        return '学習分析';
      case '/nationwide-aggregation':
        return '全国模試集計';
      case '/user-management':
        return 'ユーザー一覧';
      case ROUTE_PATH.ADMIN_MANAGEMENT:
        return '管理者登録';
      case '/school-management':
        return '学校マスタ管理';
      case '/question-category':
        return '問題カテゴリ登録';
      case ROUTE_PATH.CHANGE_PASSWORD:
        return 'パスワード変更';
      case ROUTE_PATH.SCHOOL_SUMAMARY:
        return '学校別利用状況';
      case ROUTE_PATH.STUDENT_SUMAMARY:
        return '個人別利用状況';
      case ROUTE_PATH.MINI_TEST_ANALYSIS:
        return '小テスト分析';
      case ROUTE_PATH.OVERALL_RESULTS:
        return '全体結果';
      case ROUTE_PATH.STATISTIC_BY_SCHOOL:
        return '学校別集計';
      case ROUTE_PATH.RATE_OF_CORRECT_ANSWER:
        return '問題別正答率';
      case ROUTE_PATH.RANK_STATISTIC:
        return '順位集計';
      case ROUTE_PATH.PERSONAL_RESULT:
        return '個人結果';
      default:
        break;
    }
  };

  const firstTitleBr = () => {
    let titleIdx = -1;
    const pathArray = listPath.map((listPathName: any) => {
      return listPathName.map((item: any) => {
        return item.split('/').slice(1)[0];
      });
    });

    pathArray.map((item: any, idx: number) => {
      if (item.indexOf(location.pathname.split('/').slice(1)[0]) !== -1) titleIdx = idx;
    });
    if (titleIdx !== -1) {
      return firstTitle[titleIdx];
    } else return false;
  };

  if (!isLogin) return <Navigate to={ROUTE_PATH.LOGIN} replace />;

  if ((isTeacher || isFormTeacher) && IGNORE_ROLE_TEACHER.includes(location.pathname))
    return <Navigate to={ROUTE_PATH.QUESTION_MANAGEMENT} replace />;

  if (isSchoolManager && IGNORE_ROLE_SCHOOL_MANAGER.includes(location.pathname))
    return <Navigate to={ROUTE_PATH.QUESTION_MANAGEMENT} replace />;

  if (isAdminNational && IGNORE_ROLE_ADMIN_NATIONAL.includes(location.pathname))
    return <Navigate to={ROUTE_PATH.QUESTION_MANAGEMENT} replace />;

  if (!profile.id) {
    return (
      <Row align='middle' justify={'center'}>
        <Spin />
      </Row>
    );
  }

  return (
    <Layout>
      <Navbar />
      <Layout>
        <Sider width={300} style={{ background: colorBgContainer }}>
          <Sidebar />
        </Sider>
        <Layout style={{ position: 'relative', minHeight: 'calc(100vh - 80px)' }}>
          <Breadcrumb style={{ margin: '10px 20px' }}>
            {firstTitleBr() && <Breadcrumb.Item>{firstTitleBr()}</Breadcrumb.Item>}
            <Breadcrumb.Item className={`${firstTitleBr() ? 'pink-bread' : null}`}>
              {titlePage()}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className='title'>{titlePage(true)}</div>
          <Content
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
