import { useProfileStore } from 'src/store/profile/useProfileStore';

export const usePermission = () => {
  const { profile } = useProfileStore();

  return {
    isTeacher: profile?.role === 'TEACHER',
    isFormTeacher: profile?.role === 'FORM_TEACHER',
    isSchoolManager: profile?.role === 'SCHOOL_MANAGER',
    isAdminApp: profile?.role === 'ADMIN_APP',
    isAdminNational: profile?.role === 'ADMIN_NATIONAL',
  };
};
