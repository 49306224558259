import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  mutation SIGN_IN($data: LoginInput!) {
    signIn(data: $data) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT = gql`
  mutation LOGOUT($accountId: String!) {
    logout(accountId: $accountId) {
      loggedOut
    }
  }
`;
