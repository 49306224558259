import { gql } from '@apollo/client';

export const List_Ended_National_Test = gql`
  query List_Ended_National_Test($input: FilterNationalTestAggregateInput!) {
    listEndedNationalTest(input: $input) {
      id
      name
      # testTime
    }
  }
`;

export const NATIONAL_TEST_CLASS = gql`
  query NATIONAL_TEST_CLASS($input: AggregateSubjectRateAllInput!) {
    aggregateClassRate(input: $input) {
      classes {
        abbreviations
        className
        passRatio
        point
        pointAverage
        schoolName
        subjects {
          subjectName
          code
          maxPoint
          point
        }
        testName
        totalPass
        totalPoint
        totalStudent
      }
      testTime
    }
  }
`;

export const NATIONAL_TEST_CLASS_All = gql`
  query NATIONAL_TEST_CLASS_All($input: AggregateSubjectRateAllInput!) {
    aggregateClassRateAll(input: $input) {
      nationwide {
        passRatio
        point
        pointAverage
        subjects {
          point
          totalQuestion
          maxPoint
          point
          code
          subjectName
        }
        testName
        totalPass
        totalPoint
        totalStudent
      }
      testTime
    }
  }
`;

export const Aggregate_By_Student = gql`
  query Aggregate_By_Student($input: AggregateByStudentAllInput!) {
    aggregateByStudent(input: $input) {
      abbreviations
      accountId
      className
      classRank
      formTestName
      fullName
      identityNumber
      isPass
      nationalRank
      totalStudent
      point
      questions {
        id
        isCorrect
      }
      rankFormTest
      rankFormTestInSchool
      schoolName
      schoolRank
      classRank
      schoolYearName
      subjects {
        code
        id
        maxPoint
        point
        subjectName
      }
      totalPoint
    }
  }
`;

export const Aggregate_By_Student_All = gql`
  query Aggregate_By_Student_All($input: AggregateByStudentAllInput!) {
    aggregateByStudentAll(input: $input) {
      nationalRank
      exactRatio
      pointAverage
      subjects {
        code
        maxPoint
        point
        subjectName
      }
    }
  }
`;

export const Aggregate_All = gql`
  query Aggregate_All($input: AggregateAllInput!) {
    aggregateAll(input: $input) {
      schools {
        testName
        abbreviations
        point
        totalPoint
        passRatioPreviousYear
        totalStudent
        passRatio
        totalPass
        pointAverage
        subjects {
          subjectName
          code
          point
          maxPoint
        }
      }
      testTime
    }
  }
`;

export const Aggregate_All_National = gql`
  query Aggregate_All_National($input: AggregateAllInput!) {
    aggregateAllNational(input: $input) {
      testTime
      dataTotal {
        maxPoint
        passRatio
        passRatioPreviousYear
        point
        subjects {
          code
          maxPoint
          point
          subjectName
        }
        totalPass
        totalStudent
      }
    }
  }
`;

export const Aggregate_Class_Question_Rate = gql`
  query Aggregate_Class_Question_Rate($input: AggregateClassQuestionRateAllInput!) {
    aggregateClassQuestionRate(input: $input) {
      abbreviations
      questions {
        id
        rateExact
      }
      schoolName
      schools {
        className
        passRatio
        pointAverage
        questions {
          id
          rateExact
        }
        totalPass
        totalPoint
        totalRate
        totalStudent
      }
      totalRate
    }
  }
`;

export const Aggregate_Class_Question_Rate_All = gql`
  query Aggregate_Class_Question_Rate_All($input: AggregateClassQuestionRateAllInput!) {
    aggregateClassQuestionRateAll(input: $input) {
      questions {
        id
        rateExact
      }
      subjects {
        code
        maxPoint
        point
      }
    }
  }
`;

export const Get_Years = gql`
  query Get_Years {
    getYears {
      year
    }
  }
`;
