import React from 'react';
import { Empty } from 'antd';

const LocaleTable: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {Empty.PRESENTED_IMAGE_SIMPLE}
      <p>データがありません</p>
    </div>
  );
};

export default LocaleTable;
