import { useLazyQuery } from '@apollo/client';
import { useRequest } from 'ahooks';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import EmptySelect from 'src/components/EmptySelect';
import { List_Ended_National_Test } from 'src/graphql/Aggregate.graphql';
import groupBy from 'lodash/groupBy';

const FormItemTest = ({ value, onChange, form, schoolId, schoolYearIds, disabled }: any) => {
  const [getListEndedNationalTest, { data }] = useLazyQuery(List_Ended_National_Test);

  useRequest(
    async () => {
      if (!schoolId) return [];

      return getListEndedNationalTest({
        variables: {
          input: {
            schoolIds: schoolId?.length ? schoolId : [schoolId],
            schoolYearIds,
          },
        },
      });
    },
    {
      debounceWait: 300,
      refreshDeps: [schoolId, schoolYearIds],
    },
  );

  const options = useMemo(() => {
    const r = data?.listEndedNationalTest?.map((it: any) => {
      return {
        label: it?.name,
        value: it?.id,
      };
    });
    const lost = groupBy(r, 'label');

    const listTest = Object.keys(lost).map((it) => {
      return {
        label: it,
        value: JSON.stringify(lost[it].map((it: any) => it.value)),
      };
    });
    return listTest;
  }, [data?.listEndedNationalTest]);

  const onChangeVal = (val: any) => {
    onChange(val);
  };

  return (
    <Select
      onSelect={onChangeVal}
      value={value}
      notFoundContent={<EmptySelect />}
      disabled={disabled}
      placeholder=' 模擬試験名'
      options={options}
    />
  );
};

export default FormItemTest;
