import dayjs from 'dayjs';

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const base64ToBlob = (base64: any, type: any) => {
  const base64Slice = base64.split(',')[1];
  const binStr = window.atob(base64Slice.replace(/\s/g, ''));
  const len = binStr.length;
  const buffer = new ArrayBuffer(len);
  const arr = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  const blob = new Blob([arr], { type: type });
  return URL.createObjectURL(blob);
};

const status = ['実施前', '実施中', '終了'] as const;
const statusNational = ['実施前', '実施中', '終了確定まち', '終了'] as const;

export const renderStatusScheduleMiniTest = (startDate: any, endDate: any) => {
  let statusSchedule;
  const start = dayjs(startDate).unix();
  const end = dayjs(endDate).unix();
  const now = dayjs().unix();

  // case
  if (now > start && now < end) {
    return (statusSchedule = status[1]);
  }

  if (now < start) {
    return (statusSchedule = status[0]);
  }
  if (now > end) {
    return (statusSchedule = status[2]);
  }
  return statusSchedule;
};

export const renderStatusScheduleNationalTest = (startDate: any, endDate: any, ended: boolean) => {
  let statusSchedule;
  const start = dayjs(startDate).unix();
  const end = dayjs(endDate).unix();
  const now = dayjs().unix();

  // case

  if (now > start && now < end && !ended) {
    return (statusSchedule = statusNational[1]);
  }

  if (now < start && !ended) {
    return (statusSchedule = statusNational[0]);
  }
  if (now > end && !ended) {
    return (statusSchedule = statusNational[2]);
  }
  if (ended) {
    return (statusSchedule = statusNational[3]);
  }

  return statusSchedule;
};

export const convertPointToRank = (totalCorrect: number, totalQuestion: number) => {
  let rank = 'E';
  const rate = Math.ceil((totalCorrect * 100) / totalQuestion);

  if (rate >= 0 && rate <= 9) {
    return (rank = 'E');
  }
  if (rate >= 10 && rate <= 29) {
    return (rank = 'D');
  }
  if (rate >= 30 && rate <= 49) {
    return (rank = 'C');
  }
  if (rate >= 50 && rate <= 69) {
    return (rank = 'B');
  }
  if (rate >= 70 && rate <= 89) {
    return (rank = 'A');
  }
  if (rate >= 90) {
    return (rank = 'S');
  }
  return rank;
};

export const makeId = (length: number) => {
  let id = '';

  // Generate 10 random characters (A-Z, a-z, 0-9) using a loop
  for (let i = 0; i < length; i++) {
    // Generate a random number between 0 and 61
    const randomNumber = Math.floor(Math.random() * 62);

    // Convert the random number to a character (A-Z, a-z, 0-9) using the charCodeAt method
    id += String.fromCharCode(
      randomNumber < 26
        ? 65 + randomNumber // A-Z
        : randomNumber < 52
        ? 97 + randomNumber - 26 // a-z
        : 48 + randomNumber - 52, // 0-9
    );
  }

  // Return the ID
  return id;
};

export const EXT_IMAGE = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'webp'];
export const isImage = (file: any) => {
  if (!file) return false;

  const name = file?.name?.split('.');

  return file.type?.includes('image') && EXT_IMAGE.includes(name[name?.length - 1]?.toLowerCase());
};

export const customLabelRadial = {
  id: 'customLabelRadial',
  afterDatasetsDraw(chart: any) {
    const {
      ctx,
      scales: { r },
    } = chart;

    const labels: any = r._pointLabels;

    r._pointLabelItems.forEach((ctxLabel: any, idx: number) => {
      ctx.save();

      ctx.textAlign = ctxLabel.textAlign;
      ctx.font = '17px Arial';
      ctx.fillStyle = '#A5A072';
      ctx.fillText(labels[idx][0], ctxLabel.x - 5, ctxLabel.y + 18);

      // ctx.fillStyle = 'black';
      // ctx.fillText(labels[idx][1], ctxLabel.x, ctxLabel.y + 25);
    });
  },
};

export const listSchoolHardCode = [
  '札幌校',
  '仙台校',
  '大宮校',
  '東京校',
  '立川校',
  '千葉校',
  '横浜校',
  '名古屋校',
  '大阪校',
  '広島校',
  '福岡校',
  '沖縄校',
];

export const listSchoolSummary = [
  '札幌ビューティーアート専門学校',
  '仙台ビューティーアート専門学校',
  '大宮ビューティー＆ブライダル専門学校',
  '東京ビューティーアート専門学校',
  '東京ビューティー＆ブライダル専門学校',
  '千葉ビューティー＆ブライダル専門学校',
  '横浜ビューティー＆ブライダル専門学校',
  '名古屋ビューティーアート専門学校',
  '大阪ビューティーアート専門学校',
  '広島ビューティー＆ブライダル専門学校',
  '福岡ビューティーアート専門学校',
  '沖縄ビューティーアート専門学校',
];
