import { gql } from '@apollo/client';

export const GET_INFO = gql`
  query GET_INFO {
    me {
      id
      role
      identityNumber
      email
      status
      fullName
      schoolId
      school {
        name
        abbreviations
        code
      }
    }
  }
`;
