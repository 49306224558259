/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery } from '@apollo/client';
import { Table } from 'antd';
import React, { useMemo } from 'react';
import {
  Aggregate_Class_Question_Rate,
  Aggregate_Class_Question_Rate_All,
} from 'src/graphql/Aggregate.graphql';
import * as XLSX from 'xlsx';
import FilterAggregate from '../StatisticBySchool/FilterAggregate/FilterAggregate';
import styles from './index.module.scss';
import LocaleTable from 'src/components/LocaleTable';
import { PopupNoti } from 'src/components/PopupNoti';
import { formatPercent } from 'src/lib/utils';

const RateOfCorrectAnswer = () => {
  const [getAggregateClassRate, { data: listAggregateClassQuestionRate, loading }] = useLazyQuery(
    Aggregate_Class_Question_Rate,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [
    getAggregateClassRateAll,
    { data: listAggregateClassQuestionRateAll, loading: loadingAll },
  ] = useLazyQuery(Aggregate_Class_Question_Rate_All, {
    fetchPolicy: 'no-cache',
    onError(error: any) {
      PopupNoti.error(
        `${
          error?.message === 'NOT_FINISH_ALL_SCHOOL_YET'
            ? '集計が行われていますので、少々お待ちください。'
            : error?.message
        }`,
      );
    },
  });

  const dataFormat = useMemo(() => {
    return !!listAggregateClassQuestionRate?.aggregateClassQuestionRate.length
      ? listAggregateClassQuestionRate?.aggregateClassQuestionRate[0].schools.map((it: any) => {
          const { questions, ...rest } = it;

          const questionsObj = questions?.reduce((acc: any, it: any, index: any) => {
            acc[`question-${index}`] = it.rateExact;
            return acc;
          }, {});

          return {
            ...rest,
            ...questionsObj,
          };
        })
      : [];
  }, [listAggregateClassQuestionRate?.aggregateClassQuestionRate]);

  const dataQuestion = new Array(55).fill(1)?.map((_, idx) => {
    return {
      title: idx + 1,
      width: 80,
      dataIndex: `question-${idx}`,
      key: `question-${idx}`,
      align: 'center',
      render: (value: number) => {
        return <p>{formatPercent(value * 100)}%</p>;
      },
    };
  });

  const columns: any = [
    {
      title: '問題番号',
      dataIndex: 'className',
      fixed: 'left',
      align: 'center',
      width: 110,
    },
    {
      title: '合計',
      fixed: 'left',
      align: 'center',
      width: 80,
      render: (record: any) => {
        return <p>{`${formatPercent(record?.totalRate * 100)}%`}</p>;
      },
    },
    ...dataQuestion,
  ];

  const onSearch = (values: any) => {
    getAggregateClassRateAll({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
    getAggregateClassRate({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
  };

  const handleExportExcel = () => {
    const tbl = document.getElementById('tableData');
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(tbl);
    const refillArr = (arr: any, indexOfPercent: number) => {
      const newAr = arr.map((item: number, idx: number) => {
        if (idx < indexOfPercent) {
          return item;
        } else return `${+(item * 100).toFixed(2)}%`;
      });
      return newAr;
    };
    // Loại bỏ các dòng trống giữa sheet bằng filter và map.
    const dataWithoutEmptyRows = XLSX.utils
      .sheet_to_json(ws, { header: 1 })
      .filter((row: any) => row.join('').trim() !== '')
      .map((row: any, indexRow: number) => {
        if (indexRow === 0) return [...row];
        // ;
        // if (indexRow < 3) {
        //   return [...refillArr(row, 2)];
        // }
        else return [...refillArr(row, 1)];
      });
    const wsFinal = XLSX.utils.aoa_to_sheet(dataWithoutEmptyRows);
    // Ghi dữ liệu vào Sheet mới không có dòng trống.

    // wsFinal['!cols'] = [{ wch: 20 }, { wch: 200 }, { wch: 200 }];
    wsFinal['!cols'] = [{ wch: 14 }];
    XLSX.utils.book_append_sheet(wb, wsFinal, 'sheet1');
    XLSX.writeFile(wb, 'StatisticRateCorrectAnswer.xlsx');
  };

  return (
    <div className={styles.wrap}>
      <FilterAggregate
        onFilter={onSearch}
        jsonData={listAggregateClassQuestionRate?.aggregateClassQuestionRate}
        onDownloadFile={handleExportExcel}
      />

      <Table
        id='tableData'
        // rowClassName={(record, index) =>
        //   index === listAggregateClassQuestionRate?.aggregateClassQuestionRate.length - 1 &&
        //   !record.name
        //     ? styles.hide
        //     : ''
        // }
        columns={columns}
        locale={{
          emptyText: <LocaleTable />,
        }}
        rowKey={'className'}
        dataSource={dataFormat}
        scroll={{ x: 1400, y: 500 }}
        pagination={false}
        loading={loading || loadingAll}
        summary={() => {
          const pageData = listAggregateClassQuestionRate?.aggregateClassQuestionRate;
          const questionData = listAggregateClassQuestionRateAll?.aggregateClassQuestionRateAll;
          const nationalExactRatio = questionData
            ? (questionData?.subjects?.reduce(
                (acc: any, cur: any) => acc + cur.point / cur.maxPoint,
                0,
              ) /
                questionData?.subjects?.length) *
              100
            : 0;
          return (
            !!pageData?.length && (
              <Table.Summary fixed='top'>
                <Table.Summary.Row>
                  <Table.Summary.Cell className='font-bold' align='center' index={0}>
                    全国正答率
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className='font-bold' align='center' index={1}>
                    {nationalExactRatio ? +formatPercent(nationalExactRatio).toFixed(2) : 0}%
                  </Table.Summary.Cell>
                  {listAggregateClassQuestionRateAll?.aggregateClassQuestionRateAll.questions.map(
                    (v: any, idx: number) => {
                      return (
                        <React.Fragment key={`question-${idx}`}>
                          <Table.Summary.Cell className='font-bold' align='center' index={idx + 2}>
                            {+(v.rateExact * 100).toFixed(2)}%
                          </Table.Summary.Cell>
                        </React.Fragment>
                      );
                    },
                  )}
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell align='center' index={0}>
                    {pageData?.[0]?.abbreviations}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={1}>
                    {+(pageData[0]?.totalRate * 100).toFixed(2)}%
                  </Table.Summary.Cell>

                  {pageData?.[0]?.questions.map((v: any, idx: number) => {
                    return (
                      <React.Fragment key={`question-${idx}`}>
                        <Table.Summary.Cell align='center' index={idx + 2}>
                          {+(v.rateExact * 100).toFixed(2)}%
                        </Table.Summary.Cell>
                      </React.Fragment>
                    );
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            )
          );
        }}
      />
    </div>
  );
};

export default RateOfCorrectAnswer;
