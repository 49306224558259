import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useMemo } from 'react';
import EmptySelect from 'src/components/EmptySelect';
import { ADMIN_GET_SCHOOL, TEACHER_GET_MY_SCHOOL } from 'src/graphql/School.graphql';
import { usePermission } from 'src/hook/usePermission';

const FormItemMultipleSchool = ({ onChange, form, resetFields, isMutilple }: any) => {
  const { isAdminApp, isAdminNational } = usePermission();

  const isAdmin = isAdminApp || isAdminNational;

  const { data, loading } = useQuery(isAdmin ? ADMIN_GET_SCHOOL : TEACHER_GET_MY_SCHOOL);

  const options = useMemo(() => {
    if (loading || !data?.adminGetAllSchools?.length) return [];

    const newData =
      data?.adminGetAllSchools?.map((it: any) => {
        return {
          label: `${it?.abbreviations}` || '',
          value: it?.id,
        };
      }) ?? [];

    if (newData?.length && isMutilple) {
      newData.unshift({
        label: '全国',
        value: 'ALL',
      });
    }

    return newData;
  }, [data, loading]);

  const onChangeVal = (val: string) => {
    if (val === 'ALL') {
      const newVal = data?.adminGetAllSchools?.map((it: any) => it?.id);
      onChange(newVal);
    } else {
      onChange(val);
    }
    if (resetFields) form.resetFields(resetFields);
  };

  return (
    <Select
      onChange={onChangeVal}
      notFoundContent={<EmptySelect />}
      placeholder='学校名'
      options={options}
    />
  );
};

export default FormItemMultipleSchool;
