/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import styles from './index.module.scss';
import * as XLSX from 'xlsx';
import ModalRankStatistic from '../ModalRankStatistic';
import FilterAggregate from 'src/pages/StatisticBySchool/FilterAggregate/FilterAggregate';
import { Aggregate_By_Student, Aggregate_By_Student_All } from 'src/graphql/Aggregate.graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_SUBJECTS } from 'src/graphql/Question.graphql';
import LocaleTable from 'src/components/LocaleTable';

import uniqBy from 'lodash/uniqBy';

const filterArrayClass = (data: any) => {
  if (!data?.length) return [];

  const classUnique = uniqBy(data, 'className');

  return classUnique.map((it: any) => ({ text: it?.className, value: it?.className }));
};

const RankStatistic = () => {
  const [visibleModal, setVisibleModal] = useState({
    record: {},
    isOpen: false,
  });

  const { data: subjectsData } = useQuery(GET_SUBJECTS);

  const [getAggregateByStudent, { data: listAggregateByStudent, loading }] = useLazyQuery(
    Aggregate_By_Student,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getAggregateByStudentAll, { data: listAggregateByStudentAll, loading: loadingAll }] =
    useLazyQuery(Aggregate_By_Student_All, {
      fetchPolicy: 'no-cache',
    });

  const { dataFormat, filterClass } = useMemo(() => {
    const dataFormat = listAggregateByStudent?.aggregateByStudent?.map((it: any, idx: any) => {
      return {
        ...it,
        key: idx,
      };
    });

    const filterClass = filterArrayClass(listAggregateByStudent?.aggregateByStudent);

    return {
      dataFormat,
      filterClass,
    };
  }, [listAggregateByStudent?.aggregateByStudent]);

  const [filterValue, setFilterValue] = useState([]);

  const subjectColumns = subjectsData?.subjects?.map((it: any, index: any) => {
    return {
      title: it.name,
      align: 'center',
      width: 100,
      render: (_: any, record: any) => {
        const item = record?.subjects?.find((v: any) => v.code === it.code);

        return <p>{item?.point}</p>;
      },
    };
  });

  const columns: any = [
    {
      title: '所属',
      fixed: 'left',
      children: [
        {
          title: '学校',
          fixed: 'left',
          dataIndex: 'abbreviations',
          width: 75,
        },
        {
          title: '氏名',
          fixed: 'left',
          dataIndex: 'fullName',
          width: 100,
        },
        {
          title: 'クラス',
          fixed: 'left',
          width: 90,
          dataIndex: 'className',
          filters: filterClass,
          filteredValue: filterValue,
          onFilter: (value: any, record: any) => {
            return record.className.indexOf(value) === 0;
          },
        },
      ],
    },
    {
      title: '順位',
      fixed: 'left',
      children: [
        {
          title: '全国',
          fixed: 'left',
          dataIndex: 'nationalRank',
          width: 60,
          sorter: (a: any, b: any) => a.nationalRank - b.nationalRank,
        },
        {
          title: '学校',
          fixed: 'left',
          dataIndex: 'rankFormTestInSchool',
          width: 60,
          sorter: (a: any, b: any) => a.rankFormTestInSchool - b.rankFormTestInSchool,
        },
        {
          title: 'クラス',
          fixed: 'left',
          dataIndex: 'classRank',
          width: 60,
          sorter: (a: any, b: any) => a.classRank - b.classRank,
        },
      ],
    },
    {
      title: '総合 得点',
      fixed: 'left',
      dataIndex: 'point',
      key: 'point',
      width: 55,
    },
    {
      title: '教科別得点',
      fixed: 'left',
      key: 'children',
      dataIndex: 'subjects',
      children: subjectColumns ? subjectColumns : [],
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 85,
      render: (record: any) => {
        return (
          <button onClick={() => onOpenModal(record)} className={styles.btnDetail}>
            詳細
          </button>
        );
      },
    },
  ];

  const onOpenModal = (record: any) => {
    setVisibleModal({
      record,
      isOpen: true,
    });
  };

  const onFilter = (values: any) => {
    getAggregateByStudent({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
    getAggregateByStudentAll({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
  };

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const tbl = document.getElementById('tableData');
    const ws = XLSX.utils.table_to_sheet(tbl);

    const dataWithoutEmptyRows = XLSX.utils
      .sheet_to_json(ws, { header: 1 })
      .filter((row: any) => row.join('').trim() !== '')
      .map((arr: any, idx) => {
        if (idx < 2) return [...arr];
        arr.splice(arr.indexOf('詳細'), 1);
        return [...arr];
      });
    const wsFinal = XLSX.utils.aoa_to_sheet(dataWithoutEmptyRows);
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 1, c: 6 } },
      { s: { r: 0, c: 7 }, e: { r: 0, c: 16 } },
    ];
    wsFinal['!merges'] = merge;
    XLSX.utils.book_append_sheet(wb, wsFinal, 'sheet1');
    XLSX.writeFile(wb, 'StatisticRank.xlsx');
  };

  return (
    <div className={styles.wrap}>
      <FilterAggregate
        onFilter={onFilter}
        jsonData={dataFormat}
        onDownloadFile={handleExportExcel}
        isMutilple={true}
      />
      <Table
        id='tableData'
        rowKey='key'
        columns={columns}
        locale={{
          emptyText: <LocaleTable />,
        }}
        dataSource={dataFormat}
        scroll={{ x: 1880, y: 850 }}
        pagination={false}
        loading={loading}
        onChange={(pagination, filters: any, sorter, extra) => {
          setFilterValue(filters?.className);
        }}
      />
      {!!visibleModal.isOpen && (
        <ModalRankStatistic
          visible={visibleModal}
          setVisible={setVisibleModal}
          listAggregateByStudentAll={listAggregateByStudentAll?.aggregateByStudentAll}
        />
      )}
    </div>
  );
};

export default RankStatistic;
