import { gql } from '@apollo/client';

export const ADMIN_CREATE_SCHOOL = gql`
  mutation ADMIN_CREATE_SCHOOL($data: CreateSchoolInput!) {
    adminCreateSchool(data: $data)
  }
`;

export const ADMIN_CREATE_CLASS = gql`
  mutation ADMIN_CREATE_CLASS($data: CreateClassInput!) {
    adminCreateClass(data: $data)
  }
`;

export const ADMIN_DELETE_SCHOOL = gql`
  mutation ADMIN_DELETE_SCHOOL($where: SchoolWhereUniqueInput!) {
    adminDeleteSchool(where: $where)
  }
`;

export const ADMIN_UPDATE_CLASS = gql`
  mutation ADMIN_UPDATE_CLASS(
    $data: UpdateClassInput!
    # $schoolId: String!
    $where: ClassWhereUniqueInput!
  ) {
    adminUpdateClass(data: $data, where: $where)
  }
`;

export const ADMIN_DELETE_CLASS = gql`
  mutation ADMIN_DELETE_CLASS($where: ClassWhereUniqueInput!) {
    adminDeleteClass(where: $where)
  }
`;

export const ADMIN_GET_SCHOOL = gql`
  query ADMIN_GET_SCHOOL {
    adminGetAllSchools {
      id
      name
      abbreviations
      deletedAt
    }
  }
`;

export const TEACHER_GET_MY_SCHOOL = gql`
  query TEACHER_GET_MY_SCHOOL {
    getMySchool {
      id
      name
      abbreviations
      deletedAt
    }
  }
`;

export const ADMIN_GET_FACULTIES = gql`
  query ADMIN_GET_FACULTIES($schoolId: String!) {
    getSchoolFaculties(schoolId: $schoolId) {
      id
      name
      code
    }
  }
`;

export const ADMIN_GET_LIST_SCHOOL = gql`
  query ADMIN_GET_LIST_SCHOOL(
    $skip: Int
    $take: Int
    $where: SchoolWhereInput
    $orderBy: [SchoolOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    totalSchool(where: $where)
    adminGetAllSchools(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      name
      code
      abbreviations
      createdAt
      updatedAt
      deletedAt
      schoolFaculty {
        code
        id
        name
        account {
          id
          role
          isUserTest
          isGraduated
          deletedAt
        }
        schoolYear {
          year
          id
          account {
            id
            role
            isGraduated
            deletedAt
            isUserTest
          }
          class {
            account {
              id
              role
              deletedAt
              isUserTest
              isGraduated
            }
            updatedAt
            deletedAt
            id
            name
          }
        }
      }
      account {
        id
        role
        isUserTest
        deletedAt
        isGraduated
      }
    }
  }
`;

export const ADMIN_GET_LIST_SCHOOL_STUDENT = gql`
  query ADMIN_GET_LIST_SCHOOL {
    adminGetAllSchools {
      code
      abbreviations
      id
      name
      deletedAt
      schoolFaculty {
        id
        code
        name
        schoolYear {
          class {
            id
            name
            deletedAt
          }
          id
          year
          deletedAt
        }
        deletedAt
      }
    }
    totalSchool
  }
`;

export const ADMIN_DETAIL_CLASS = gql`
  query ADMIN_DETAIL_CLASS($where: ClassWhereUniqueInput!) {
    getClassById(where: $where) {
      name
      schoolYear {
        year
        id
        schoolFaculty {
          id
          name
          code
          school {
            id
            name
          }
        }
      }
    }
  }
`;

export const ADMIN_DETAIL_SCHOOL = gql`
  query ADMIN_DETAIL_SCHOOL($where: SchoolWhereUniqueInput!) {
    adminGetSchoolById(where: $where) {
      name
      id
      code
      abbreviations
    }
  }
`;

export const ADMIN_CREATE_SCHOOL_YEAR = gql`
  mutation ADMIN_CREATE_SCHOOL_YEAR($data: CreateSchoolYearInput!) {
    adminCreateSchoolYear(data: $data)
  }
`;

export const ADMIN_GET_SCHOOL_YEAR = gql`
  query GET_SCHOOL_YEAR($where: WhereSchoolYearsInput!) {
    getSchoolYears(where: $where) {
      year
      id
      class {
        id
        name
      }
      schoolFaculty {
        code
        id
        name
      }
      schoolFacultyId
    }
  }
`;

export const FILTER_SCHOOL_YEARS = gql`
  query FILTER_SCHOOL_YEARS($input: FilterSchoolYearInput!) {
    filterSchoolYears(input: $input) {
      year
      id
      schoolFaculty {
        code
        id
        name
      }
      class {
        id
        name
      }
    }
  }
`;

export const GET_CLASSES = gql`
  query GET_CLASSES($where: ClassWhereInput) {
    getClasses(where: $where) {
      createdAt
      deletedAt
      id
      name
      updatedAt
    }
  }
`;

export const ADMIN_GET_LIST_SCHOOL_FACULITIES = gql`
  query AdminlistSchoolFaculties($where: SchoolFacultyWhereInput) {
    adminlistSchoolFaculties(where: $where) {
      code
      name
      schoolFacultyIds
      classes {
        name
        classIds
      }
      schoolYears {
        year
        schoolYearIds
      }
    }
  }
`;

export const GET_SCHOOL_ANALYNIS = gql`
  query GET_SCHOOL_ANALYNIS(
    $where: AccountWhereInput
    $orderBy: [AccountOrderByWithRelationAndSearchRelevanceInput!]
    $skip: Int
    $take: Int
  ) {
    schoolAiAnalysis1(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      alCount
      alMedium
      className
      key
      rankA
      rankB
      rankC
      rankD
      rankE
      rankS
      rate
      registerNumber
      schoolName
      selfMedium
      selfTestCount
      userUsed
    }
  }
`;
