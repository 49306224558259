import { Button, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { isEmpty } from 'lodash';
import FormItemMultipleSchoolYear from '../FormItemMultipleSchoolYear';
import FormItemSchool from '../FormItemSchool';
import FormItemTest from '../FormItemTest';
import styles from './index.module.scss';
import { usePermission } from 'src/hook/usePermission';
import FormItemMultipleSchool from 'src/pages/StatisticBySchool/FormItemMultipleSchool';

interface IFilterAggregateProps {
  onFilter: any;
  onDownloadFile?: () => void;
  jsonData: any;
  isMutilple?: boolean;
}

const FilterAggregate: React.FunctionComponent<IFilterAggregateProps> = ({
  onFilter,
  jsonData,
  onDownloadFile,
  isMutilple = false,
}) => {
  const [form] = Form.useForm();
  const { isAdminApp, isAdminNational } = usePermission();
  const isAdmin = isAdminApp || isAdminNational;

  return (
    <Form form={form} onFinish={onFilter}>
      <Row className={styles.wrapSelect}>
        <Col span={18}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name={'schoolId'} rules={[{ required: true, message: '必須' }]}>
                {isAdmin ? (
                  <FormItemMultipleSchool
                    form={form}
                    resetFields={['schoolYearId', 'sendNationalTestId']}
                    isMutilple={isMutilple}
                  />
                ) : (
                  <FormItemSchool
                    form={form}
                    resetFields={['schoolYearId', 'sendNationalTestId']}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={['schoolId']}>
                {() => {
                  return (
                    <Form.Item name={'schoolYearId'} rules={[{ required: true, message: '必須' }]}>
                      <FormItemMultipleSchoolYear
                        form={form}
                        schoolId={form.getFieldValue('schoolId')}
                        resetFields={['sendNationalTestId']}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item dependencies={['schoolId', 'schoolYearId']}>
                {() => {
                  return (
                    <Form.Item
                      name={'sendNationalTestId'}
                      rules={[{ required: true, message: '必須' }]}
                    >
                      <FormItemTest
                        form={form}
                        schoolId={form.getFieldValue('schoolId')}
                        schoolYearIds={form.getFieldValue('schoolYearId')}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button htmlType='submit' className={classNames(styles.btn, styles.btnFilter)}>
                集計
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            disabled={isEmpty(jsonData)}
            className={classNames(styles.btn, styles.btnPrint)}
            onClick={onDownloadFile}
          >
            {/* <IconPrint /> */}
            <img src={process.env.PUBLIC_URL + '/images/do-excel.svg'} alt='' width={26} />
            ダウンロード
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterAggregate;
