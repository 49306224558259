export const getRegexPassword = () => {
  const regex = /^[0-9a-zA-Z]{6,14}/;
  return regex;
};

export const formatPercent = (value: number) => {
  if (value === 0 || !value) return 0;

  if (value % 2 === 0) return value;

  return parseFloat(value.toFixed(2));
};
