import { Button } from 'antd';
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';
import ModalCommon from 'src/components/Modal/Modal';
import { customLabelRadial } from 'src/utils';
import styles from './index.module.scss';
import { sortBy } from 'lodash';
import { formatPercent } from 'src/lib/utils';

interface IModalProps {
  visible: {
    record: any;
    isOpen: boolean;
  };
  setVisible: ({ record, isOpen }: any) => void;
  listAggregateByStudentAll: any;
}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  customLabelRadial,
);

const ModalRankStatistic = ({ visible, setVisible, listAggregateByStudentAll }: IModalProps) => {
  const subjectsSort = useMemo(() => {
    if (!visible.record?.subjects?.length) return [];

    const rateExactly = sortBy(visible?.record?.subjects, 'code').map((subject: any) => {
      const point = subject?.point ?? 0;
      const maxPoint = subject?.maxPoint ?? 0;

      if (point === 0 && maxPoint === 0) return { ...subject, rate: 0 };

      return {
        ...subject,
        rate: formatPercent((point / maxPoint) * 100) ?? 0,
      };
    });

    return rateExactly;
  }, [visible.record?.subjects]);

  // const rateExactly = visible.record?.subjects?.map((subject: any) =>
  //   formatPercent(((subject?.point || 0) / (subject?.maxPoint || 1)) * 100),
  // );

  const rateExactly = subjectsSort?.map((subject: any) => {
    const sj = visible.record?.subjects.find((it: any) => it?.code === subject?.code);

    return formatPercent(((sj?.point || 0) / (sj?.maxPoint || 1)) * 100);
  });

  const totalQuestion = visible.record?.subjects?.map((subject: any) => subject?.maxPoint / 2);

  const averageRatePoint =
    (subjectsSort.reduce((acc: number, cur: any) => acc + cur.point, 0) / 110) * 100;

  const data: any = {
    labels: sortBy(visible?.record?.subjects, 'code').map((subject: any) => [
      subject?.subjectName,
      `設問数${subject?.maxPoint / 2}`,
    ]),
    datasets: [
      {
        label: 'あなた',
        data: rateExactly,
        backgroundColor: '#A5A07200',
        borderColor: '#00f',
        pointBackgroundColor: '#00f',
        borderWidth: 1,
        pointRadius: 2,
      },
      {
        label: '全国',
        //@ts-ignore
        data: subjectsSort?.map((subject: any) => {
          const sj = listAggregateByStudentAll?.subjects.find(
            (it: any) => it?.code === subject?.code,
          );

          return formatPercent(((sj?.point || 0) / (sj?.maxPoint || 1)) * 100);
        }),
        backgroundColor: '#A5A07200',
        borderColor: '#f00',
        pointBackgroundColor: '#f00',
        borderWidth: 1,
        pointRadius: 2,
      },
    ],
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        color: '#fff',
        suggestedMin: 0,
        suggestedMax: Math.max(...(totalQuestion || [1])),
        pointLabels: {
          font: {
            size: 14,
          },
          color: 'transparent',
        },
      },
    },
  };

  const onVisible = () => {
    setVisible({
      record: {},
      isOpen: false,
    });
  };

  const nationalExactRatio = listAggregateByStudentAll
    ? (listAggregateByStudentAll?.subjects.reduce(
        (acc: any, cur: any) => acc + cur.point / cur.maxPoint,
        0,
      ) /
        listAggregateByStudentAll?.subjects.length) *
      100
    : 0;

  return (
    <div>
      <ModalCommon
        open={visible.isOpen}
        onCancel={onVisible}
        title={`氏名: ${visible.record?.fullName}`}
        className={styles.modal}
        width={'900px'}
      >
        <div className={styles.mypage}>
          <div style={{ marginBottom: '20px' }} className={styles.top}>
            <table className='m-auto rounded-2xl overflow-hidden'>
              <colgroup>
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
              </colgroup>
              <thead className='bg-[#E8E8E8]'>
                <tr>
                  <th className='font-bold p-2'>
                    <span className='text-[16px] text-[#111] opacity-100 special'></span>
                  </th>

                  <th
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className='font-bold p-2'
                  >
                    <span className='text-[16px] text-[#000000] font-bold'>全国平均</span>
                  </th>
                  <th className='font-bold p-2'>
                    <span className='text-[16px] text-[#000000] font-bold'>あなたの結果</span>
                  </th>
                  <th className='font-bold p-2'>
                    <span className={'text-[16px] text-[#000000] font-bold'}>差分</span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-[#FFFFFF]'>
                <tr>
                  <td className='text-center p-3'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      正答率
                    </span>
                  </td>

                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {nationalExactRatio ? +formatPercent(nationalExactRatio).toFixed(2) : 0}
                    </span>
                    %
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {+averageRatePoint.toFixed(2) ?? 0}
                    </span>
                    %
                  </td>
                  <td
                    className={classNames('text-center text-[16px] text-[#000000]', {
                      'text-[#FF0000]': averageRatePoint - nationalExactRatio < 0,
                    })}
                  >
                    <span
                      className={classNames('text-center text-[16px] text-[#000000]', {
                        'text-[#FF0000]': averageRatePoint - nationalExactRatio < 0,
                      })}
                    >
                      {+formatPercent(averageRatePoint - nationalExactRatio).toFixed(2)}%
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#111] opacity-100 special'>得点</span>
                  </td>

                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {listAggregateByStudentAll?.pointAverage ?? 0}
                    </span>
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {visible?.record?.point ?? 0}
                    </span>
                  </td>
                  <td
                    className={classNames('text-center p-3 text-[16px] text-[#000000]', {
                      'text-[#FF0000]':
                        (visible?.record?.point ?? 0) -
                          (listAggregateByStudentAll?.pointAverage ?? 0) <
                        0,
                    })}
                  >
                    {
                      +(
                        (visible?.record?.point ?? 0) -
                        (listAggregateByStudentAll?.pointAverage ?? 0)
                      ).toFixed(2)
                    }
                  </td>
                </tr>
                <tr>
                  <td className='text-center p-3'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      全国ランキング
                    </span>
                  </td>

                  <td className='text-center p-3' colSpan={3}>
                    <span className='text-[16px] text-[#000000] '>
                      {visible?.record?.nationalRank ?? 0}位 / {visible?.record?.totalStudent ?? 0}
                      人中
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={styles.top}>
            <div className={styles.mypage__subjects}>
              <table>
                <colgroup>
                  <col span={1} style={{ width: '240px' }} />
                  <col span={1} style={{ width: '120px' }} />
                  <col span={1} style={{ width: '120px' }} />
                  <col span={1} style={{ width: '120px' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className='font-bold'>
                      <span className='text-[20px] text-[#111] opacity-100 special'>分野別</span>
                    </th>

                    <th className='text-[14px] whitespace-nowrap'>
                      全国
                      <br />
                      平均点
                    </th>
                    <th className='text-[14px] whitespace-nowrap'>
                      あなた
                      <br />
                      の得点
                    </th>
                    <th className='text-[14px] whitespace-nowrap'>差分</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectsSort?.map((subjectItem: any) => {
                    const sj = listAggregateByStudentAll?.subjects.find(
                      (it: any) => it?.code === subjectItem?.code,
                    );

                    const diff = +((subjectItem?.point || 0) - (sj?.point || 0)).toFixed(2);

                    return (
                      <tr key={subjectItem.id}>
                        <td>{subjectItem.subjectName}</td>
                        <td className='text-base text-center'>
                          {+sj?.point.toFixed(2) ?? 0} /{sj.maxPoint}点
                        </td>
                        <td className='text-base text-center'>{`${
                          +subjectItem.point.toFixed(2) || 0
                        }/${sj.maxPoint}点`}</td>
                        <td
                          className={classNames('text-base text-center', {
                            'text-[#FF0000]': diff < 0,
                          })}
                        >
                          {diff}点
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='mypage__chart min-w-[500px] '>
              <Radar data={data} options={options} />
            </div>
          </div>

          <div className={styles.mypage__result}>
            <table className='table-layout'>
              <colgroup>
                <col span={1} style={{ width: '50px' }} />
                <col span={1} style={{ width: '150px' }} />
                <col span={1} style={{ width: '150px' }} />
                <col span={1} style={{ width: '150px' }} />
                <col span={1} style={{ width: '150px' }} />
                <col span={1} style={{ width: '150px' }} />
              </colgroup>
              <tbody className='flex items-center rounded-lg w-[1040px] ml-[64px]'>
                <p
                  className='bg-[#E1E1E1] text-xl font-bold w-[40px] text-center mb-4'
                  style={{ padding: '88px 0 80px', borderRadius: '20px 0 0 20px' }}
                >
                  判 定 結 果
                </p>
                <tr
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '12px 0',
                    flexWrap: 'wrap',
                    height: '320px',
                    columnGap: '4px',
                    borderRadius: '0 20px 20px 0',
                  }}
                >
                  {visible.record?.questions?.map((question: any, index: number) => {
                    return (
                      <div
                        key={question?.id}
                        style={{ width: '150px', height: '28px' }}
                        className={index % 2 === 0 ? 'bg-[#FAFAFA]' : 'bg-white'}
                      >
                        <td
                          style={{
                            padding: '8px 28px',
                            margin: 0,
                            width: '150px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p style={{ width: '60px', textAlign: 'left' }}>問 {index + 1}</p>
                          {question?.isCorrect ? (
                            <p className='font-bold text-xl text-[#FF0000]'>〇</p>
                          ) : (
                            <p className='font-bold text-black text-base'>X</p>
                          )}
                        </td>
                      </div>
                    );
                  })}
                  {[1, 2, 3, 4, 5].map((it: number) => {
                    return (
                      <td
                        key={it}
                        className={'bg-white'}
                        style={{
                          padding: '8px 28px',
                          margin: 0,
                          width: '150px',
                          display: 'flex',
                          height: '28px',
                        }}
                      ></td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div style={{ cursor: 'pointer', width: '80px', margin: 'auto' }}>
              <Button
                onClick={onVisible}
                className={classNames(['text_center text-[20px] mt-2', styles.btnClose])}
              >
                閉じる
              </Button>
            </div>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default ModalRankStatistic;
