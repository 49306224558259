import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React from 'react';
import EmptySelect from 'src/components/EmptySelect';
import { ADMIN_GET_SCHOOL, TEACHER_GET_MY_SCHOOL } from 'src/graphql/School.graphql';
import { usePermission } from 'src/hook/usePermission';

const FormItemSchool = ({ value, onChange, form, resetFields, disabled }: any) => {
  const { isAdminApp, isAdminNational } = usePermission();
  const isAdmin = isAdminApp || isAdminNational;

  const { data: listSchool } = useQuery(isAdmin ? ADMIN_GET_SCHOOL : TEACHER_GET_MY_SCHOOL);

  const onChangeVal = (val: any) => {
    onChange(val);

    if (resetFields) form.resetFields(resetFields);
  };

  return (
    <Select
      onSelect={onChangeVal}
      value={value}
      disabled={disabled}
      notFoundContent={<EmptySelect />}
      placeholder='学校名'
      options={
        !isAdmin
          ? [
              {
                label: listSchool?.getMySchool?.abbreviations,
                value: listSchool?.getMySchool?.id,
              },
            ]
          : listSchool?.adminGetAllSchools?.map((it: any) => {
              return {
                label: it?.abbreviations || '',
                value: it?.id,
              };
            })
      }
    />
  );
};

export default FormItemSchool;
