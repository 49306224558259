import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from './route.constant';
import React, { Suspense } from 'react';

import PageNotFound from 'src/pages/PageNotFound/PageNotFound';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import AuthLayout from 'src/layouts/AuthLayout/AuthLayout';
import MainLayout from 'src/layouts/MainLayout/MainLayout';
import OverallResults from 'src/pages/OverallResults';
import RankStatistic from 'src/pages/OverallResults/components/RankStatistic';
import StatisticBySchool from 'src/pages/StatisticBySchool';
import RateOfCorrectAnswer from 'src/pages/RateOfCorrectAnswer';

const AdminReg = React.lazy(() => import('src/pages/AdministratorRegistration/AdminRegister'));
const LoginForm = React.lazy(() => import('src/pages/Login/LoginForm'));
const Homepage = React.lazy(() => import('src/pages/SchoolManagement/SchoolManagement'));
const ImageManagement = React.lazy(() => import('src/pages/ImageManagement/ImageManagement'));
const MiniTestCreation = React.lazy(() => import('src/pages/MiniTestCreation/MiniTestCreation'));
const QuestionManagement = React.lazy(() => import('src/pages/QuestionManagement'));
const QuestionSingleRegistration = React.lazy(
  () => import('src/pages/QuestionManagement/features/QuestionSingleRegistration'),
);
const QuizDelivery = React.lazy(() => import('src/pages/QuizDelivery'));
const MockTestCreation = React.lazy(() => import('src/pages/TestManagement/MockTestCreation'));
const TestList = React.lazy(() => import('src/pages/TestManagement/TestList'));
const NationwideTrialDelivery = React.lazy(
  () => import('src/pages/TestRun/NationwideTrialDelivery'),
);
const UserRegistration = React.lazy(() => import('src/pages/UserRegistration/UserRegistration'));
const SchoolSummary = React.lazy(() => import('src/pages/SchoolSummary/SchoolSummary'));
const QuestionCategory = React.lazy(() => import('src/pages/QuestionCategory/QuestionCategory'));
const StudentSummary = React.lazy(() => import('src/pages/StudentSummary/StudentSummary'));
const ChangePassword = React.lazy(() => import('src/pages/ChangePassword'));
const PersonalResult = React.lazy(() => import('src/pages/PersonalResult'));
const MiniTestAnalysis = React.lazy(() => import('src/pages/MiniTestAnalysis/MiniTestAnalysis'));
const MiniTestAnalysisDetail = React.lazy(
  () => import('src/pages/MiniTestAnalysisDetail/MiniTestAnalysisDetail'),
);
const NationalTrialExam = React.lazy(() => import('src/pages/NationalTrialExam'));
// const StudentStatistic = React.lazy(() => import('src/pages/StudentSummary/StudentStatistic'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTE_PATH.LOGIN,
            element: (
              <Suspense fallback={null}>
                <LoginForm />
              </Suspense>
            ),
          },
        ],
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: ROUTE_PATH.QUESTION_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <QuestionManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.TEST_LIST,
            element: (
              <Suspense fallback={null}>
                <TestList />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.QUESTION_MANAGEMENT_DETAIL,
            element: (
              <Suspense fallback={null}>
                <QuestionSingleRegistration />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.MOCK_TEST_CREATION,
            element: (
              <Suspense fallback={null}>
                <MockTestCreation />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.QUIZ_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <MiniTestCreation />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.IMAGE_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <ImageManagement />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.NATIONWIDE,
            element: (
              <Suspense fallback={null}>
                <NationwideTrialDelivery />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.QUIZ_DELIVERY,
            element: (
              <Suspense fallback={null}>
                <QuizDelivery />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.NATIONAL_TRIAL_EXAMINATION,
            element: (
              <Suspense fallback={null}>
                <NationalTrialExam />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.USER_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <UserRegistration />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.ADMIN_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <AdminReg />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.SCHOOL_MANAGEMENT,
            element: (
              <Suspense fallback={null}>
                <Homepage />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.SCHOOL_SUMAMARY,
            element: (
              <Suspense fallback={null}>
                <SchoolSummary />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.STUDENT_SUMAMARY,
            element: (
              <Suspense fallback={null}>
                <StudentSummary />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.OVERALL_RESULTS,
            element: (
              <Suspense fallback={null}>
                <OverallResults />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.STATISTIC_BY_SCHOOL,
            element: (
              <Suspense fallback={null}>
                <StatisticBySchool />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.RATE_OF_CORRECT_ANSWER,
            element: (
              <Suspense fallback={null}>
                <RateOfCorrectAnswer />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.RANK_STATISTIC,
            element: (
              <Suspense fallback={null}>
                <RankStatistic />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.PERSONAL_RESULT,
            element: (
              <Suspense fallback={null}>
                <PersonalResult />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.QUESTION_CATEGORY,
            element: (
              <Suspense fallback={null}>
                <QuestionCategory />
              </Suspense>
            ),
          },

          {
            path: ROUTE_PATH.MINI_TEST_ANALYSIS,
            element: (
              <Suspense fallback={null}>
                <MiniTestAnalysis />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.MINI_TEST_ANALYSIS_DETAIL,
            element: (
              <Suspense fallback={null}>
                <MiniTestAnalysisDetail />
              </Suspense>
            ),
          },
          {
            path: ROUTE_PATH.CHANGE_PASSWORD,
            element: (
              <Suspense fallback={null}>
                <ChangePassword />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
