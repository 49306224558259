import styled from "styled-components";

export const SidebarWrapper = styled.div`
  .ant-menu-item.ant-menu-item-selected {
    background: #fafafa;
    color: #f7758a;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: #fafafa;
    color: #f7758a;
  }
`;
