/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLazyQuery, useQuery } from '@apollo/client';
import { Table } from 'antd';
import moment from 'moment';
import LocaleTable from 'src/components/LocaleTable';
import { NATIONAL_TEST_CLASS, NATIONAL_TEST_CLASS_All } from 'src/graphql/Aggregate.graphql';
import { GET_SUBJECTS } from 'src/graphql/Question.graphql';
import * as XLSX from 'xlsx';
import FilterAggregate from './FilterAggregate/FilterAggregate';
import styles from './index.module.scss';
import sortBy from 'lodash/sortBy';
import { PopupNoti } from 'src/components/PopupNoti';
import { formatPercent } from 'src/lib/utils';

const StatisticBySchool = () => {
  const { data: subjectsData } = useQuery(GET_SUBJECTS);

  const [getAggregateClassRate, { data: listAggregateClassRate, loading }] = useLazyQuery(
    NATIONAL_TEST_CLASS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const dataSource = listAggregateClassRate?.aggregateClassRate;

  const [getAggregateClassRateAll, { data: listAggregateClassRateAll, loading: loadingAll }] =
    useLazyQuery(NATIONAL_TEST_CLASS_All, {
      fetchPolicy: 'no-cache',
      onError(error: any) {
        PopupNoti.error(
          `${
            error?.message === 'NOT_FINISH_ALL_SCHOOL_YET'
              ? '集計が行われていますので、少々お待ちください。'
              : error?.message
          }`,
        );
      },
    });

  const subjectColumns = subjectsData?.subjects?.map((it: any, index: any) => {
    return {
      title: it.name,
      align: 'center',
      key: `record-${index}`,
      render: (_: any, record: any) => {
        const sj = record?.subjects?.find((item: any) => item?.code === it?.code);

        return (
          <p>{sj?.maxPoint === 0 ? '0%' : `${formatPercent((sj?.point / sj?.maxPoint) * 100)}%`}</p>
        );
      },
    };
  });

  const getColumns = (testTime: any): any[] => {
    return [
      {
        title: `${testTime}回目`,
        dataIndex: 'className',
        align: 'center',
        width: '80px',
        fixed: 'left',
      },
      {
        title: 'クラス別',
        align: 'center',
        children: [
          { title: '受験者', dataIndex: 'totalStudent', fixed: 'left', align: 'center' },
          { title: '合格者', dataIndex: 'totalPass', fixed: 'left', align: 'center' },
          {
            title: '合格率',
            dataIndex: 'passRatio',
            fixed: 'left',
            align: 'center',
            render: (value: any) => {
              return `${formatPercent(value * 100)}%`;
            },
          },
          {
            title: '平均点',
            dataIndex: 'pointAverage',
            fixed: 'left',
            align: 'center',
            render: (value: any) => {
              return `${formatPercent(value)}`;
            },
          },
        ],
      },
      {
        title: '教科別(正答率)',
        align: 'center',
        children: subjectColumns ? subjectColumns : [],
      },
    ];
  };

  const onFilter = (values: any) => {
    // setTestTimeLabel(JSON.parse(values?.sendNationalTestId)?.testTime);

    getAggregateClassRateAll({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
    getAggregateClassRate({
      variables: {
        input: {
          schoolId: values?.schoolId,
          schoolYearIds: values?.schoolYearId,
          sendNationalTestId: JSON.parse(values?.sendNationalTestId),
        },
      },
    });
  };

  const handleExportExcel = () => {
    const tbl = document.getElementById('tableData');
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(tbl);

    const refillArr = (arr: any) => {
      const newAr = arr.map((item: any, idx: number) => {
        if (idx < 3 || idx === 4) {
          return item;
        } else return `${+(item * 100).toFixed(2)}%`;
      });
      return newAr;
    };

    const dataWithoutEmptyRows = XLSX.utils
      .sheet_to_json(ws, { header: 1 })
      .filter((row: any) => row.join('').trim() !== '')
      .map((row: any, idx: number) => {
        if (idx > 1) {
          return [...refillArr(row)];
        } else return [...row];
      });

    const wsFinal = XLSX.utils.aoa_to_sheet(dataWithoutEmptyRows);
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
      { s: { r: 0, c: 5 }, e: { r: 0, c: 14 } },
    ];
    wsFinal['!merges'] = merge;
    XLSX.utils.book_append_sheet(wb, wsFinal, 'sheet1');
    XLSX.writeFile(wb, `StatisticBySchool-${moment().format('YYYY/MM/DD')}.xlsx`);
  };

  return (
    <div className={styles.wrap}>
      <FilterAggregate
        onDownloadFile={handleExportExcel}
        onFilter={onFilter}
        jsonData={dataSource}
      />

      {dataSource?.length > 0 ? (
        dataSource?.map((item: any, idx: number) => {
          return (
            <Table
              id='tableData'
              key={idx}
              rowKey={'schoolName'}
              columns={getColumns(item.testTime)}
              dataSource={item.classes}
              scroll={{ x: 1400, y: 500 }}
              pagination={false}
              loading={loading || loadingAll}
              locale={{
                emptyText: <LocaleTable />,
              }}
              summary={(pageData) => {
                const dataNationwide = listAggregateClassRateAll?.aggregateClassRateAll?.find(
                  (it: any) => it?.testTime === item.testTime,
                );
                let schoolTotalStudent = 0;
                let schoolSumPass = 0;
                let sumPoint = 0;

                const schoolSubjectRate: any = {
                  schoolSubjectA: 0,
                  schoolSubjectB: 0,
                  schoolSubjectC: 0,
                  schoolSubjectD: 0,
                  schoolSubjectE: 0,
                  schoolSubjectF: 0,
                  schoolSubjectG: 0,
                  schoolSubjectH: 0,
                  schoolSubjectI: 0,
                  schoolSubjectJ: 0,
                };

                const maxPointSubject: any = {
                  maxPointSubjectA: 0,
                  maxPointSubjectB: 0,
                  maxPointSubjectC: 0,
                  maxPointSubjectD: 0,
                  maxPointSubjectE: 0,
                  maxPointSubjectF: 0,
                  maxPointSubjectG: 0,
                  maxPointSubjectH: 0,
                  maxPointSubjectI: 0,
                  maxPointSubjectJ: 0,
                };

                pageData?.forEach((it) => {
                  schoolTotalStudent += it.totalStudent;
                  schoolSumPass += it.totalPass;
                  sumPoint += Number(it.point);
                  sortBy(it.subjects, 'code').forEach((subject: any) => {
                    let key = `schoolSubject${subject.code}`;
                    let maxPointKey = `maxPointSubject${subject.code}`;
                    schoolSubjectRate[key] += Number(subject.point);
                    maxPointSubject[maxPointKey] += Number(subject.maxPoint);
                  });
                });

                return (
                  !!pageData.length && (
                    <Table.Summary fixed='top'>
                      <Table.Summary.Row>
                        <Table.Summary.Cell className='font-bold' align='center' index={0}>
                          全体
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={1}>
                          {dataNationwide?.nationwide?.totalStudent || 0}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={2}>
                          {dataNationwide?.nationwide?.totalPass || 0}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={3}>
                          {dataNationwide?.nationwide?.passRatio
                            ? formatPercent(dataNationwide?.nationwide?.passRatio * 100)
                            : 0}
                          %
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className='font-bold' align='center' index={4}>
                          {dataNationwide?.nationwide?.pointAverage
                            ? formatPercent(dataNationwide?.nationwide?.pointAverage)
                            : 0}
                        </Table.Summary.Cell>

                        {dataNationwide?.nationwide?.subjects?.map((it: any, idx: number) => {
                          return (
                            <Table.Summary.Cell
                              className='font-bold'
                              align='center'
                              index={idx + 5}
                              key={it?.code}
                            >
                              {formatPercent((it?.point / it?.maxPoint) * 100)}%
                            </Table.Summary.Cell>
                          );
                        })}
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell align='center' index={0}>
                          {pageData?.[0]?.abbreviations}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={1}>
                          {schoolTotalStudent}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={2}>
                          {schoolSumPass}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={3}>
                          {formatPercent((schoolSumPass / schoolTotalStudent) * 100)}%
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={4}>
                          {formatPercent(sumPoint / schoolTotalStudent)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={5}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectA
                              ? schoolSubjectRate.schoolSubjectA / maxPointSubject.maxPointSubjectA
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={6}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectB
                              ? schoolSubjectRate.schoolSubjectB / maxPointSubject.maxPointSubjectB
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={7}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectC
                              ? schoolSubjectRate.schoolSubjectC / maxPointSubject.maxPointSubjectC
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={8}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectD
                              ? schoolSubjectRate.schoolSubjectD / maxPointSubject.maxPointSubjectD
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={9}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectE
                              ? schoolSubjectRate.schoolSubjectE / maxPointSubject.maxPointSubjectE
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={10}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectF
                              ? schoolSubjectRate.schoolSubjectF / maxPointSubject.maxPointSubjectF
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={11}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectG
                              ? schoolSubjectRate.schoolSubjectG / maxPointSubject.maxPointSubjectG
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={12}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectH
                              ? schoolSubjectRate.schoolSubjectH / maxPointSubject.maxPointSubjectH
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={13}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectI
                              ? schoolSubjectRate.schoolSubjectI / maxPointSubject.maxPointSubjectI
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align='center' index={14}>
                          {`${formatPercent(
                            (maxPointSubject.maxPointSubjectJ
                              ? schoolSubjectRate.schoolSubjectJ / maxPointSubject.maxPointSubjectJ
                              : 0) * 100,
                          )}%`}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )
                );
              }}
            />
          );
        })
      ) : (
        <Table
          id='tableData'
          columns={getColumns('')}
          dataSource={[]}
          pagination={false}
          loading={loading}
          locale={{
            emptyText: <LocaleTable />,
          }}
        />
      )}
    </div>
  );
};

export default StatisticBySchool;
