import { Button } from 'antd';
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';
import ModalCommon from 'src/components/Modal/Modal';
import { customLabelRadial } from 'src/utils';
import styles from './index.module.scss';
import { formatPercent } from 'src/lib/utils';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  customLabelRadial,
);

interface IModalProps {
  visible: {
    record: any;
    isOpen: boolean;
  };
  setVisible: ({ record, isOpen }: any) => void;
  listAggregateAllNational?: any;
}

const ModalOverallResults = ({ visible, setVisible, listAggregateAllNational }: IModalProps) => {
  const options = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
        color: '#fff',
        suggestedMin: 0,
        suggestedMax: 100,
        pointLabels: {
          font: {
            size: 14,
          },
          color: 'transparent',
        },
      },
    },
    ticks: {
      stepSize: 20,
    },
  };
  const subjects = useMemo(() => {
    return sortBy(visible?.record?.subjects, 'code').map((it) => {
      return {
        ...it,
        rate: formatPercent((it?.point / it?.maxPoint) * 100),
      };
    });
  }, [visible?.record?.subjects]);

  const aggregateAllNational = listAggregateAllNational.find((item: any) => {
    return item.testTime === visible.record.testTime;
  }).dataTotal;
  // const rateAllExact = aggregateAllNational?.point / aggregateAllNational?.maxPoint || 0;

  const averageRateTotalSubjectSchool =
    subjects.reduce((acc: any, cur: any) => acc + cur.rate, 0) / subjects.length;

  const averageRateTotalSubjectNational =
    (aggregateAllNational?.subjects.reduce(
      (acc: any, cur: any) => acc + cur.point / cur.maxPoint,
      0,
    ) /
      aggregateAllNational?.subjects.length) *
    100;

  // const shoolQuestionCorrect = visible?.record?.subjects?.reduce((acc: any, it: any) => {
  //   if (!it?.maxPoint) {
  //     return acc + 0;
  //   }
  //   return acc + Number(it?.point);
  // }, 0);

  // const alltotalRateQuestionCorrect = visible?.record?.subjects?.reduce((acc: any, it: any) => {
  //   if (!it?.maxPoint) {
  //     return acc + 0;
  //   }
  //   return acc + Number(it?.maxPoint);
  // }, 0);

  // const totalRateQuestionCorrect = shoolQuestionCorrect / alltotalRateQuestionCorrect;

  const data: any = {
    labels: subjects?.map((v: any) => [v?.subjectName]),
    datasets: [
      {
        label: '自校',
        data: subjects?.map((it: any) => it?.rate),
        backgroundColor: '#A5A07200',
        borderColor: '#00f',
        pointBackgroundColor: '#00f',
        borderWidth: 1,
        pointRadius: 2,
      },
      {
        label: '全国',
        data: subjects?.map((it: any) => {
          const aggregateAllNationalData = listAggregateAllNational.find((item: any) => {
            return item.testTime === visible?.record?.testTime;
          })?.dataTotal;
          const sj = aggregateAllNationalData?.subjects?.find((v: any) => v?.code === it?.code);
          return formatPercent((sj?.point / sj?.maxPoint) * 100);
        }),
        backgroundColor: '#A5A07200',
        borderColor: '#f00',
        pointBackgroundColor: '#f00',
        borderWidth: 1,
        pointRadius: 2,
      },
    ],
  };

  const onVisible = () => {
    setVisible({
      record: {},
      isOpen: false,
    });
  };

  return (
    <div className={styles.overallResults}>
      <ModalCommon
        open={visible.isOpen}
        onCancel={onVisible}
        title={`${visible.record.testName}`}
        className={styles.modal}
        width={'900px'}
      >
        <div className={styles.mypage}>
          <p className='text-[#000000] font-bold text-center lms-mb-4 text-[22px]'>
            {visible.record.abbreviations}
          </p>
          <div className='h-[3px] w-[100px] bg-[#FFFFFF] m-auto mb-6'></div>

          <div className={styles.top}>
            <table className='m-auto rounded-2xl overflow-hidden'>
              <colgroup>
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
                <col span={1} style={{ minWidth: '150px' }} />
              </colgroup>
              <thead className='bg-[#E8E8E8]'>
                <tr>
                  <th className='font-bold p-2'>
                    <span className='text-[16px] text-[#111] opacity-100 special'></span>
                  </th>
                  <th className='font-bold p-2'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      合格率
                    </span>
                  </th>
                  <th
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    className='font-bold p-2'
                  >
                    <span className='text-[16px] text-[#111] opacity-100 special'>合格者数</span>
                  </th>
                  <th className='font-bold p-2'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      受験者数
                    </span>
                  </th>
                  <th className='font-bold p-2'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      正答率
                    </span>
                  </th>
                  <th className='font-bold p-2'>
                    <span
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      className='text-[16px] text-[#111] opacity-100 special'
                    >
                      平均点
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-[#FFFFFF]'>
                <tr>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] font-bold'>全国</span>
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {formatPercent(aggregateAllNational?.passRatio * 100)}
                    </span>
                    %
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {aggregateAllNational?.totalPass}
                    </span>
                    名
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {aggregateAllNational?.totalStudent}
                    </span>
                    名
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {+averageRateTotalSubjectNational.toFixed(2) ?? 0}
                    </span>
                    %
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {formatPercent(aggregateAllNational?.point)}
                    </span>
                    点
                  </td>
                </tr>
                <tr>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] font-bold'>自校</span>
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {formatPercent(
                        (visible?.record?.totalPass / visible?.record?.totalStudent) * 100,
                      )}
                    </span>
                    %
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {visible?.record?.totalPass}
                    </span>
                    名
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {visible?.record?.totalStudent}
                    </span>
                    名
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {+averageRateTotalSubjectSchool.toFixed(2) ?? 0}
                    </span>
                    %
                  </td>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] '>
                      {formatPercent(visible?.record?.pointAverage)}
                    </span>
                    点
                  </td>
                </tr>
                <tr>
                  <td className='text-center p-3'>
                    <span className='text-[16px] text-[#000000] font-bold'>差分</span>
                  </td>
                  <td className='text-center p-3'>
                    <span
                      className={classNames('text-[16px] text-[#000000]', {
                        'text-[red]':
                          +(
                            (visible?.record?.totalPass / visible?.record?.totalStudent) * 100 -
                            (aggregateAllNational?.passRatio * 100 || 0)
                          ).toFixed(2) < 0,
                      })}
                    >
                      {formatPercent(
                        (visible?.record?.totalPass / visible?.record?.totalStudent) * 100 -
                          (aggregateAllNational?.passRatio * 100 || 0),
                      )}
                      %
                    </span>
                  </td>
                  <td className='text-center p-3'></td>
                  <td className='text-center p-3'></td>
                  <td className='text-center p-3'>
                    <span
                      className={classNames('text-[16px] text-[#000000]', {
                        'text-[red]':
                          +averageRateTotalSubjectSchool.toFixed(2) -
                            +averageRateTotalSubjectNational.toFixed(2) <
                          0,
                      })}
                    >
                      {+(
                        +averageRateTotalSubjectSchool.toFixed(2) -
                        +averageRateTotalSubjectNational.toFixed(2)
                      ).toFixed(2) || 0}
                      %
                    </span>
                  </td>
                  <td className='text-center p-3'>
                    <span
                      className={classNames('text-[16px] text-[#000000]', {
                        'text-[red]':
                          visible?.record?.pointAverage - aggregateAllNational?.point < 0,
                      })}
                    >
                      {+(visible?.record?.pointAverage - aggregateAllNational?.point).toFixed(2) ||
                        0}
                      点
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={styles.bottom}>
            <div className={styles.mypage__subjects}>
              <table className='table-layout'>
                <colgroup>
                  <col span={1} style={{ width: '285px' }} />
                  <col span={1} style={{ width: '85px' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className='font-bold'>
                      <span className='text-[20px] text-[#111] opacity-100 special'>分野別</span>
                    </th>
                    <th className='text-[14px] whitespace-nowrap'>
                      全国
                      <br />
                      正答率
                    </th>
                    <th className='text-[14px] whitespace-nowrap'>
                      自校
                      <br />
                      正答率
                    </th>

                    <th className='text-[14px] whitespace-nowrap'>差分</th>
                  </tr>
                </thead>
                <tbody>
                  {subjects?.map((subjectItem: any) => {
                    const sj = aggregateAllNational?.subjects?.find(
                      (v: any) => v?.code === subjectItem?.code,
                    );
                    const sjRate = formatPercent((sj?.point / sj?.maxPoint) * 100) || 0;
                    const diff = subjectItem.rate - sjRate;

                    return (
                      <tr key={subjectItem.id}>
                        <td>{subjectItem.subjectName}</td>

                        <td className='text-base text-center'>{sjRate || 0}%</td>
                        <td className='text-base text-center'>{`${subjectItem.rate}%`}</td>
                        <td
                          className={classNames('text-base text-center', {
                            'text-[red]': diff < 0,
                          })}
                        >
                          {diff ? formatPercent(diff) : 0}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='mypage__chart min-w-[600px] m-auto'>
              <Radar data={data} options={options} />
            </div>
          </div>

          <div>
            <div style={{ cursor: 'pointer', width: '80px', margin: 'auto' }}>
              <Button
                onClick={onVisible}
                className={classNames(['text_center text-[20px] mt-2', styles.btnClose])}
              >
                閉じる
              </Button>
            </div>
          </div>
        </div>
      </ModalCommon>
    </div>
  );
};

export default ModalOverallResults;
