import { atom } from 'jotai';
import { TRole } from 'src/constants/ROLES';

export interface IProfile {
  id: number;
  role: TRole;
  identityNumber: number;
  email: string;
  status: string;
  fullName: string;
  schoolId: string;
  school: {
    name: string;
    abbreviations: string;
    code: string;
  };
}

export const profileAtom = atom<Partial<IProfile>>({});
